const Color = {
  PasianGreen: '#01A3A4',
  BlackPearl: '#1A2732',
  Midnight: '#1F2C41',
  Aluminium: '#828B93',
  Chambray: '#4E5A6A',
  White: '#FFFFFF',
  Snow: '#FAFAFA',
  Whisper: '#E5E5E5',
  SurfieGreen: '#087879',
  MediumTurquoise: '#5DC8C8',
  MorningGlory: '#99DADB',
  Black70: '#4D4D4D',
  Emerald: '#4ED485',
  RadicalRed: '#F45255',
  Sunglow: '#FFD12E',
  MoodyBlue: '#7B7FDE',
  Malibu: '#52C0E5',
  DarkOrange: '#FF8F00'
};

export default Color;
