/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const postTokenJobs = /* GraphQL */ `
  mutation PostTokenJobs($data: AWSJSON) {
    postTokenJobs(data: $data)
  }
`;
export const postSearchJobs = /* GraphQL */ `
  mutation PostSearchJobs($data: AWSJSON) {
    postSearchJobs(data: $data)
  }
`;
export const createGlobalConfig = /* GraphQL */ `
  mutation CreateGlobalConfig(
    $input: CreateGlobalConfigInput!
    $condition: ModelGlobalConfigConditionInput
  ) {
    createGlobalConfig(input: $input, condition: $condition) {
      id
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateGlobalConfig = /* GraphQL */ `
  mutation UpdateGlobalConfig(
    $input: UpdateGlobalConfigInput!
    $condition: ModelGlobalConfigConditionInput
  ) {
    updateGlobalConfig(input: $input, condition: $condition) {
      id
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteGlobalConfig = /* GraphQL */ `
  mutation DeleteGlobalConfig(
    $input: DeleteGlobalConfigInput!
    $condition: ModelGlobalConfigConditionInput
  ) {
    deleteGlobalConfig(input: $input, condition: $condition) {
      id
      key
      value
      createdAt
      updatedAt
    }
  }
`;
