/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import React, { Component, Fragment, createRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import {
  Card, CardBody, Progress, UncontrolledPopover, PopoverBody, Button
} from 'reactstrap';
import parse from 'html-react-parser';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Header from '../Common/Header';
import ErrorBoundary from '../Common/ErrorBoundary';
import { postTokenJobs, postSearchJobs } from '../../graphql/mutations';
import './custom.scss';
import Icon from '../Common/Icon';
import InfoTable from '../Common/InfoTable';
import Search from '../Common/Search';
import Pagination from '../Common/Pagination';
import FilterTable from '../Common/FilterTable';
import { listGlobalConfigs } from '../../graphql/queries';
import { notify } from '../../helper/helper';
import {
  LocationMapping, SkillsMatch
} from './constant';
import Loader from '../Common/Loader';

class JobMatching extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isApiCall: true,
      searchText: '',
      content: {
        data: [],
        error: [],
        fileName: props.history.location.state.fileName,
        fileData: null,
      },
      dataTable: props.history.location.state.dataTable || [],
      tableFields: [
        {
          name: 'Job Title',
          field: 'Name',
          type: 'String',
          sort: false,
          width: 40,
          render: (row) => this.renderRowTable(row, 'Name')
        },
        {
          name: 'Skills Match',
          field: 'Score',
          type: 'Number',
          defaultSort: true,
          defaultSortOrder: 'desc',
          width: 10,
          sort: true,
          render: (row) => this.renderRowTable(row, 'Score')
        },
        {
          name: 'Type',
          field: 'Type',
          sort: true,
          width: 10,
          type: 'String',
          render: (row) => this.renderRowTable(row, 'Type')
        },
        {
          name: 'Contract',
          field: 'Contract',
          width: 10,
          sort: true,
          type: 'String',
          render: (row) => this.renderRowTable(row, 'Contract')
        },
        {
          name: 'Location',
          field: 'Location',
          sort: true,
          width: 30,
          type: 'String',
          render: (row) => this.renderRowTable(row, 'Location')
        },
      ],
      pagination: { page: 0, rowsPerPage: 10, isNext: false },
      queryParts: props.history.location.state.queryParts || [],
      isOpenDetail: false,
      loading: false,
      isLoadingDetail: false,
      jobDetail: null,
      fullTextSearch: '',
      locationList: props.history.location.state.locationList
    };
  }

  dropzoneRef = createRef();

  openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (this.dropzoneRef.current) {
      this.dropzoneRef.current.open();
    }
  };

  // Handle set state
  handleSetState = (data) => {
    // if (this._isMounted) {
    this.setState((prevState) => ({ ...prevState, ...data }));
    // }
    return true;
  };

  getTokenJob = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(listGlobalConfigs, {
          filter: {
            key: {
              eq: 'session_id',
            },
          },
        })
      );
      if (result.data.listGlobalConfigs.items.length !== 0) {
        const sessionID = result.data.listGlobalConfigs.items[0].value;
        const res = await API.graphql(
          graphqlOperation(postTokenJobs, {
            data: JSON.stringify({
              sessionID,
            }),
          })
        );
        const data = JSON.parse(res.data?.postTokenJobs);
        if (data.statusCode === 200) {
          return JSON.parse(data.data);
        }
      }
    } catch (error) {
      console.error('the getTokenJob function occur an error: ', error);
    }
    return '';
  }

  parseSkillsMatch = (job) => {
    if (!job || !this.state.queryParts) {
      return;
    }

    const skillsMatch = [];
    const skillsNotMatch = [];
    const queryPart = this.state.queryParts;
    const queryPartScrore = job.queryPartScores;

    SkillsMatch.map((item) => {
      queryPart.map((val, idx) => {
        if (val.field === item.key) {
          const curPos = queryPartScrore[idx];
          const curItem = val.items[0];
          const skill = {
            label: '',
            value: curPos === 0 ? 0 : 1
          };
          if (curItem.subParts && item.key !== 'workfield') {
            let subLable = `${item.label  } `;
            curItem.subParts.map((p) => {
              subLable += `${p.fieldLabel}: ${p.items[0].label} `;
            });
            skill.label = subLable;
          } else {
            skill.label = `${item.label} ${curItem.label ? curItem.label : ''}`;
          }

          if (curPos === 1) {
            skillsMatch.push(skill);
          } else {
            skillsNotMatch.push(skill);
          }
        }
      });
    });
    return skillsMatch.concat(skillsNotMatch);
  }

  renderSkillsMatch = (row) => (
      <>
        <Progress id={`popover-${row.id}`} multi
          max={10}
          className="bg-Whisper"
          style={{ height: '5px', borderRadius: '10px', width: '70%' }}
        >
          <Progress bar value={row.Score} barClassName={this.renderColorProcess(row.Score)} />
          <Progress bar barClassName='skill-no-matched' />
        </Progress>
        <UncontrolledPopover
          trigger="hover"
          placement="bottom"
          target={`popover-${row.id}`}
          popperClassName="wrapper-popover"
        >
          <PopoverBody>
            {row.Skills.map((item, index) => {
              let content = null;
              if (item.value !== 1) {
                content = (
                  <div key={index}>
                    <Icon solid name="faTimesCircle" size="sm" className="mr-2" />
                    <span styles="text-transform: capitalize">{item.label}</span>
                  </div>
                );
              } else {
                content = (
                  <div key={index}>
                    <Icon solid name="faCheckCircle" size="sm" className="mr-2" />
                    <span styles="text-transform: capitalize">{item.label}</span>
                  </div>
                );
              }
              return content;
            })}
          </PopoverBody>
        </UncontrolledPopover>
      </>
    );

  // To render company name
  renderCompanyName = (row) => (
      <div className="d-flex flex-column">
        <div className="font-weight-bold text-truncated" style={{ maxWidth: '50rem' }}>{row.Title}</div>
        <div className="text-black-50 d-block">
          {row.CompanyName}
        </div>
      </div>
    );

  renderRowTable = (row, field) => {
    let content = null;
    switch (field) {
      case 'Name':
        content = (
          <div className="d-flex align-items-center" onClick={() => this.handleDrawerOpen(row)}>
            {this.renderCompanyName(row)}
            <div className="font-weight-bold">{row.Name}</div>
          </div>
        );
        break;
      case 'Score':
        content = (
          <div onClick={() => this.handleDrawerOpen(row)}>
            {this.renderSkillsMatch(row)}
          </div>
        );
        break;
      default:
        content = (
          <div onClick={() => this.handleDrawerOpen(row)}>
            {row[field]}
          </div>
        );
        break;
    }
    return content;
  }

  renderColorProcess = (percent) => {
    let color = 'bg-Amber';
    if (percent > 0 && percent < 30) {
      color = 'bg-Red';
    } else if (percent >= 30 && percent < 70) {
      color = 'bg-Amber';
    } else if (percent >= 70) {
      color = 'bg-Green';
    }
    return color;
  };

  updateDataTable = (list) => {
    this.setState({
      ...this.state,
      pagination: {
        ...this.state.pagination,
        page: 0,
        isNext: this.checkIsNext(0)
      }
    });
  };

  // Get search value from Search
  getSearchValue = (value) => {
    this.setState({ searchText: value });
  };

  handleJobsMatchingData = (data) => {
    const dataTable = [];
    const locationList = [];
    Promise.all(data.map((item) => {
      const newItem = {};
      newItem.Skills = this.parseSkillsMatch(item);
      newItem.id = item.docID;
      newItem.Title = (item.fieldValues.jobtitle[0]?.value || '').replace(/(<([^>]+)>)/ig, '');
      // check the job type is part-time or full time or both
      // let jobType = item.fieldValues.employment_type_code[0]?.value || '1';
      newItem.Type = (item.fieldValues.employment_type_code[0]?.value || '').replace(/(<([^>]+)>)/ig, '');
      // contract type
      // let contractType = item.fieldValues.contract_type_code[0]?.value || '';
      newItem.Contract = (item.fieldValues.contract_type_code[0]?.value || '').replace(/(<([^>]+)>)/ig, '').replace('contract', '');
      // get name of company
      newItem.CompanyName = (item.fieldValues.org_normalized_name[0]?.value || '').replace(/(<([^>]+)>)/ig, '');
      const percentMatched = (item.queryPartScores.reduce((pv, cv) => pv + cv, 0) / item.queryPartScores.length) * 100;
      newItem.PercentMatched = percentMatched;
      // score = matched / total
      const score = newItem.Skills.filter((i) => i.value === 1).length / newItem.Skills.length;
      if (score > 0.75) {
        newItem.Score = 100;
      } else {
        newItem.Score = Math.round((score + 0.25) * 100);
      }
      // newItem.Score = newItem.Skills.filter((i) => i.value == 1).length / newItem.Skills.length;

      newItem.ItemMatched = item.queryPartScores.reduce((pv, cv) => pv + cv, 0);
      newItem.Description = item.fieldValues.job_description[0]?.value || '';
      // handle IT skills :
      let itSkills = '';
      item.fieldValues.compskills.map((i) => {
        itSkills = `${i.value.replace(/(<([^>]+)>)/ig, '')  },`;
      });
      newItem.ItSkills = itSkills.slice(0, -1);
      // salary
      newItem.Salary = item.fieldValues.salary[0]?.value || '';

      newItem.EducationLevel = (item.fieldValues.educationlevel_international[0]?.value || '').replace(/(<([^>]+)>)/ig, '');
      newItem.Profession = item.fieldValues.profession_code[0]?.value || '';
      const minimumYears = item.fieldValues.experience_years[0].subValues.minimum[0] || '';
      const maximumYears = item.fieldValues.experience_years[0].subValues.maximum[0] || '';
      let experienceYears = '';
      if (minimumYears !== '') {
        experienceYears = `${minimumYears  } - `;
      }
      if (maximumYears !== '') {
        experienceYears += maximumYears;
      } else {
        experienceYears = experienceYears.slice(0, -3);
      }
      newItem.ExperienceYears = experienceYears;
      newItem.AdvertiserType = item.fieldValues.org_advertiser_type[0].value === '1' ? 'Direct Employer' : 'Staffing / Recruitment Agency';
      // handle to replace locations
      const tmpLocation = (item.fieldValues.city[0]?.value || '').replace(/(<([^>]+)>)/ig, '').trim();
      const validLocation = LocationMapping.find((i) => i.value === tmpLocation);
      if (validLocation) {
        newItem.Location = validLocation.label;
      } else {
        newItem.Location = 'Melbourne';
      }
      // get the list of locations for the filter
      if (newItem.Location !== '') {
        // check duplicate location
        const locationDuplicate = locationList.find((i) => i.label === newItem.Location);
        if (!locationDuplicate) {
          const newLocation = { label: newItem.Location, value: newItem.Location };
          locationList.push(newLocation);
        }
      }
      // sort list with alphabetically
      locationList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      // Language Skills
      let languageSkills = '';
      item.fieldValues.langskills_iso.map((i) => {
        languageSkills = `${i.value.replace(/(<([^>]+)>)/ig, '')  },`;
      });
      newItem.LanguageSkills = languageSkills.slice(0, -1);
      // CurrentWorkField
      newItem.CurrentWorkField = (item.fieldValues.workfield[0].subValues.workfield[0] || '').replace(/(<([^>]+)>)/ig, '');
      dataTable.push(newItem);
    }));
    this.setState({
      ...this.state,
      dataTable,
      locationList,
      loading: false
    });
  }

  mappingEducationNumber = (numberEducation) => {
    let educationLevel = '';
    switch (numberEducation) {
      case '3':
        educationLevel = 'Bachelor';
        break;
      case '4':
        educationLevel = 'Master';
        break;
      default:
        break;
    }
    return educationLevel;
  }

  handleChangePage = (page) => {
    this.setState({
      ...this.state,
      pagination: {
        ...this.state.pagination,
        page,
        isNext: this.checkIsNext(page)
      }
    });
  }

  onTextEnterChange = async () => {
    let searchJobsResult = null;
    this.setState({
      ...this.state, loading: true, isOpenDetail: false, pagination: { page: 0, rowsPerPage: 10, isNext: false }, dataTable: []
    });

    const qrp = this.state.queryParts;
    qrp.map((item) => {
      if (item.field === 'jobtitle') {
        item.items = [{
          value: `[${this.state.fullTextSearch}]`,
          synonyms: [],
          label: `[${this.state.fullTextSearch}]`,
        }];
      }
    });
    try {
      const reqToken = await this.getTokenJob();
      if (reqToken) {
        searchJobsResult = await API.graphql(graphqlOperation(postSearchJobs, {
          data: JSON.stringify({
            fileName: this.state.content.fileName,
            accessToken: reqToken,
            queryPart: qrp
          }),
        }));
        const nData = JSON.parse(searchJobsResult.data.postSearchJobs);
        if (nData.statusCode === 200) {
          const newData = JSON.parse(nData.data);
          this.setState({ ...this.state, queryParts: newData.queryParts });
          this.handleJobsMatchingData(newData.resultItems);
        } else {
          notify('No Data found.');
        }
      }
    } catch (error) {
      console.error('the onTextEnterChange function occur an error: ', error);
    }
  }

  onTextSearchChange = async (val) => {
    this.setState({ ...this.state, fullTextSearch: val });
  }

  checkIsNext = (page, dataLength) => {
    const { rowsPerPage } = this.state.pagination;
    const length = dataLength || this.state.dataTable.length;
    return !(((page + 1) * rowsPerPage >= length));
  }

  renderPagination = () => {
    const { page, rowsPerPage, isNext } = this.state.pagination;
    return (
      <Pagination
        page={page}
        rowsPerPage={rowsPerPage}
        dataLength={this.state.dataTable.length}
        handleChangePage={this.handleChangePage}
        isMore={isNext}
      />
    );
  }

  handleDrawerOpen = (row) => {
    this.setState({
      ...this.state,
      isOpenDetail: true,
      jobDetail: row
    });
  }

  handleDrawerClose = () => {
    this.setState({
      ...this.state,
      isOpenDetail: false
    });
  }

  renderTable = () => {
    const {
      loading, isLoadingDetail, dataTable, tableFields, pagination, jobDetail, isOpenDetail
    } = this.state;
    return (
      <div className="job-data-wrapper d-flex flex-grow-1">
        <div className={`Content ${this.state.isOpenDetail ? 'ContentShift' : ''}`}>
          <div className={`mt-1 ${loading || isLoadingDetail ? 'disabled' : ''}`} style={{ maxHeight: 'calc(100vh - 20rem)' }}>
            {
              loading ? (
                <div className="d-flex h-100"><Loader /></div>
              ) : (
                <PerfectScrollbar className="jobs-list-table flex-grow-1" options={{ wheelPropagation: false }}>
                  <div className="table-responsive-sm">
                    <InfoTable
                      fontSize="0.875rem"
                      fields={tableFields}
                      data={dataTable}
                      rowsPerPage={pagination.rowsPerPage}
                      page={pagination.page}
                      className="text-nowrap"
                      updateState={this.updateDataTable}
                    />
                  </div>
                </PerfectScrollbar>)
            }
          </div>
        </div>
        {isOpenDetail && <div className="job-data-detail" style={{ padding: '2rem 2rem 0 2rem', width: '38%' }}>
          <div className="Drawer h-100 d-flex flex-column">
            <div className="position-relative">
              <div className="position-absolute" style={{ right: 0 }}>
                <Icon className="cursor-pointer" name="faTimes" color="#c2c4c6" style={{ fontSize: '1.5rem' }} onClick={() => this.handleDrawerClose()} />
              </div>
              <div className="pr-4">
                <div className='portal-jobTitle font-size-1-5 font-weight-bold'>{parse(jobDetail.Title)}</div>
                <div className="d-flex w-100 my-2" style={{ justifyContent: 'flex-start' }}>
                  <Button color="primary font-weight-bold" className='job-detail-button'>Apply</Button>
                  <Button color="primary font-weight-bold" className='job-detail-button track-button'>Track</Button>
                </div>
              </div>
            </div>
            <div className="h-100 overflow-auto mt-2">
              {
                this.state.isLoadingDetail ? (
                  <div className="d-flex h-100"><Loader /></div>
                ) : (
                  <PerfectScrollbar>
                    <div className="sourceJobDetail height100Percent">
                      <p><b>Profession: </b>{jobDetail && jobDetail.Profession}</p>
                      <p><b>Current Work Field: </b>{jobDetail && jobDetail.CurrentWorkField}</p>
                      <p><b>Education Level: </b>{jobDetail && jobDetail.EducationLevel}</p>
                      <p><b>Years of Experience: </b>{jobDetail && jobDetail.ExperienceYears}</p>
                      <p><b>Part-Time / Full-Time: </b>{jobDetail && jobDetail.Type}</p>
                      <p><b>Contract Type: </b>{jobDetail && jobDetail.Contract}</p>
                      <p><b>Salary: </b>{jobDetail && jobDetail.Salary}</p>
                      <div className='mb-3'><b>Description: </b>{jobDetail && parse(jobDetail.Description)}</div>
                      <p><b>IT Skills: </b>{jobDetail && jobDetail.ItSkills}</p>
                      <p><b>Language Skills: </b>{jobDetail && jobDetail.LanguageSkills}</p>
                      <p><b>Advertiser Type: </b>{jobDetail && jobDetail.AdvertiserType}</p>
                    </div>
                  </PerfectScrollbar>
                )
              }
            </div>
          </div>
        </div>}
      </div>
    );
  };

  renderJobsMatching = () => {
    const { locationList } = this.state;
    return (
      <div className='padding-top-list-job ca-content d-flex flex-row'>
        <div className="ca-card-form">
          <Card className="ca-card-form__card h-100">
            <CardBody className="ca-card-form__card-body h-100">
              <Search onChange={this.onTextSearchChange} onKeyUp={this.onTextEnterChange} />
              <FilterTable locationList={locationList} pagination={this.renderPagination} />
              {this.renderTable()}
            </CardBody>
          </Card>
        </div>
      </div>
    );
  };

  render () {
    const headerProps = { ...this.props, getSearchValue: this.getSearchValue, backPath: '/' };
    const { isDashBoardPage } = this.state;
    if (!isDashBoardPage) {
      headerProps.pageHeaderTitle = 'Tailored job matches';
      headerProps.showBackButton = true;
    }
    return (
      <Fragment>
        <ErrorBoundary>
          <Header {...headerProps} />
        </ErrorBoundary>
        <ErrorBoundary>
          {this.renderJobsMatching()}
        </ErrorBoundary>
      </Fragment>
    );
  }
}

export default JobMatching;
