import React from 'react';
import './custom.scss';
import laptop1 from '../../../assets/images/laptop_1.svg';
import msEdgeBigIcon from '../../../assets/images/msEdge_big_icon.svg';
import chromeBigIcon from '../../../assets/images/chrome_big_icon.svg';
import firefoxBigIcon from '../../../assets/images/firefox_big_icon.svg';

function UnsupportedBrowser (props) {
  const installText = 'Test';
  return (
    <div className="unsupported-browser-wrapper d-flex flex-column align-items-center justify-content-center m-auto">
      <img src={laptop1} alt="laptop"/>
      <div className="d-flex flex-column">
        <p className="text-primary text-center font-weight-bold font-size-xl mb-4">unsupported_browser'</p>
        <div className="d-flex w-100 justify-content-around align-items-center">
          <div className="browser-icon d-flex flex-column justify-content-center align-items-center">
            <img src={msEdgeBigIcon} className="mb-2" alt="Microsoft Edge"/>
            <p className="pb-2 m-auto">Microsoft Edge</p>
            <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">{installText}</a>
          </div>
          <div className="browser-icon d-flex flex-column justify-content-center align-items-center">
            <img src={chromeBigIcon} className="mb-2" alt="Google Chrome"/>
            <p className="pb-2 m-auto">Google Chrome</p>
            <a href="https://www.google.com/chrome/">{installText}</a>
          </div>
          <div className="browser-icon d-flex flex-column justify-content-center align-items-center">
            <img src={firefoxBigIcon} className="mb-2" alt="Firefox"/>
            <p className="pb-2 m-auto">Firefox</p>
            <a href="https://www.mozilla.org/en-US/firefox/new/">{installText}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnsupportedBrowser;
