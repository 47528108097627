/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGlobalConfig = /* GraphQL */ `
  query GetGlobalConfig($id: ID!) {
    getGlobalConfig(id: $id) {
      id
      key
      value
      createdAt
      updatedAt
    }
  }
`;
export const listGlobalConfigs = /* GraphQL */ `
  query ListGlobalConfigs(
    $filter: ModelGlobalConfigFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlobalConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
