import React, { Component, Fragment } from 'react';
import {
 FormGroup, Button, Label, Input, Row, Col
} from 'reactstrap';
import { API, graphqlOperation } from 'aws-amplify';
import Header from '../Common/Header';
import ErrorBoundary from '../Common/ErrorBoundary';
import { notify } from '../../helper/helper';
import { createGlobalConfig, updateGlobalConfig } from '../../graphql/mutations';
import { listGlobalConfigs } from '../../graphql/queries';

class UserSetting extends Component {
    constructor (props) {
        super(props);
        this.state = {
            searchText: '',
            globalConfig: {
                id: '',
                key: 'session_id',
                value: '',
            },
        };
    }

    async componentDidMount () {
        try {
            const result = await API.graphql(
                graphqlOperation(listGlobalConfigs, {
                    filter: {
                        key: {
                            eq: this.state.globalConfig.key,
                        },
                    },
                })
            );
            if (result.data.listGlobalConfigs.items.length !== 0) {
                this.setState({
                    ...this.state,
                    globalConfig: result.data.listGlobalConfigs.items[0],
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    handleOnClick = async (event) => {
        try {
            if (this.state.globalConfig.id) {
                const { id, key, value } = this.state.globalConfig;
                await API.graphql(graphqlOperation(updateGlobalConfig, {
                    input: { id, key, value },
                }));
                notify('Save Success!', 'success');
            } else {
                await API.graphql(graphqlOperation(createGlobalConfig, {
                    input: {
                        key: this.state.globalConfig.key,
                        value: this.state.globalConfig.value,
                    },
                }));
                notify('Save Success!', 'success');
            }
        } catch (error) {
            console.error(error);
        }
    };

    onInputChange = (e) => {
        const val = e.target.value;
        this.setState({
            ...this.state,
            globalConfig: {
                ...this.state.globalConfig,
                value: val,
            },
        });
    };

    // Get search value from Search
    getSearchValue = (value) => {
        this.setState({ searchText: value });
    };

    render () {
        const headerProps = { ...this.props, getSearchValue: this.getSearchValue };
        headerProps.pageHeaderTitle = 'User Settings';
        return (
            <Fragment>
                <ErrorBoundary>
                    <Header {...headerProps} />
                </ErrorBoundary>
                <div className="app-content ca-content">
                    <ErrorBoundary>
                        <Row>
                            <Col md="3">
                                <FormGroup>
                                    <Label for="exampleEmail">SessionID</Label>
                                    <Input
                                        type="text"
                                        name="sessionid"
                                        placeholder="SESSIONID=xxx"
                                        defaultValue={this.state.globalConfig.value}
                                        onChange={(e) => this.onInputChange(e)}
                                    />
                                </FormGroup>
                                <Button color="primary" onClick={(e) => this.handleOnClick(e)}>
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </ErrorBoundary>
                </div>
            </Fragment>
        );
    }
}

export default UserSetting;
