import React, {
  useState,
  Fragment,
  useRef,
  useEffect
} from 'react';
import { Tooltip } from 'reactstrap';

function EllipsisText (props) {
  const {
    onClick,
    isMultiLine = false
  } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const isMounted = useRef(false);
  const elId = `tt-${props.id}`;

  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  const hideTooltip = () => {
    if (isMounted.current) {
      setTooltipOpen(false);
    }
    return true;
  };

  const toggle = ({ target }) => {
    if ((target.scrollWidth > target.clientWidth) || (isMultiLine && (target.scrollHeight > target.clientHeight))) {
      setTooltipOpen(!tooltipOpen);
    }

    const scrollBarContainer = document.querySelector('#scrollBarContainer');
    if (scrollBarContainer) {
      scrollBarContainer.addEventListener('ps-scroll-y', hideTooltip);
    }
  };

  return (
    <Fragment>
      <div className={`${props.className} ${isMultiLine ? 'text-ellipsis-multiline' : 'text-ellipsis'}`} id={elId} onClick={onClick || null}>{props.children}</div>
      <Tooltip modifiers={props.modifiers || null} fade={false} placement={props.placement || 'auto'} isOpen={tooltipOpen} target={elId} toggle={toggle}>
        {props.children}
      </Tooltip>
    </Fragment>
  );
}

export default EllipsisText;
