import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ToastContainer } from 'react-toastify';
import { routeComponents, getMenus } from '../routes';
import Sidebar from '../components/Common/Sidebar';
import 'react-toastify/dist/ReactToastify.css';
import '../custom.scss';
import {
  SCHEME_COLOR_COMMOM,
  SCHEME_COLOR_PARTICIPANT
} from '../Constants/caCommon';

export default class MasterPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      routes: []
    };
    this.appWrapperRef = React.createRef();
    this.appSidebarOverlayRef = React.createRef();
    this.appSidebarActiveClass = 'app-sidebar-mobile-open';
    this.appSidebarOverlayActiveClass = 'is-active';
    this.searchActiveClass = 'search-wrapper-open';
    this.routePrefix = '';
  }

  // Handle click on hamburger button
  handleHamburgerButtonClick = () => {
    const appWrapperEl = this.appWrapperRef.current;
    const appSidebarOverlayEl = this.appSidebarOverlayRef.current;
    if (appWrapperEl.classList.contains(this.appSidebarActiveClass)) {
      appWrapperEl.classList.remove(this.appSidebarActiveClass);
    } else {
      appWrapperEl.classList.add(this.appSidebarActiveClass);
      appSidebarOverlayEl.classList.add(this.appSidebarOverlayActiveClass);
    }
  }

  // Handle click on app-sidebar-overlay element
  handleAppSidebarOverlayClick = () => {
    const appSidebarOverlayEl = this.appSidebarOverlayRef.current;
    const appWrapperEl = this.appWrapperRef.current;
    if (appSidebarOverlayEl.classList.contains(this.appSidebarOverlayActiveClass)) {
      appSidebarOverlayEl.classList.remove(this.appSidebarOverlayActiveClass);
      appWrapperEl.classList.remove(this.appSidebarActiveClass);
    }
  }

  // Handle search box
  handleSearchBoxClick = () => {
    const appWrapperEl = this.appWrapperRef.current;
    if (appWrapperEl.classList.contains(this.searchActiveClass)) {
      appWrapperEl.classList.remove(this.searchActiveClass);
    } else {
      appWrapperEl.classList.add(this.searchActiveClass);
    }
  }

  handleRemoveSearchBoxActiveClass = () => {
    const appWrapperEl = this.appWrapperRef.current;
    if (appWrapperEl.classList.contains(this.searchActiveClass)) {
      appWrapperEl.classList.remove(this.searchActiveClass);
    }
    sessionStorage.setItem('urlBack', '');
  }

  handleScrollToTop = () => {
    const scrollBarContainer = document.querySelector('#scrollBarContainer');
    if (scrollBarContainer) {
      scrollBarContainer.scrollTop = 0;
    }
  }

  // Filter routes by user's role
  filterRoutesList = () => {
    const userRole = 'CT-Candidate';
    const allowedRoutes = [];

    const componentProps = {
      handleHamburgerButtonClick: this.handleHamburgerButtonClick,
      handleSearchBoxClick: this.handleSearchBoxClick,
      routePrefix: this.routePrefix,
      handleScrollToTop: this.handleScrollToTop,
      handleRemoveSearchBoxActiveClass: this.handleRemoveSearchBoxActiveClass
    };

    // Prepare routes list
    routeComponents.map((pRoute, pKey) => {
      if (pRoute.children) {
        pRoute.children.map((cRoute, cKey) => {
          if (cRoute.allow.includes(userRole)) {
            allowedRoutes.push(
              <Route
                key={`${pKey}-${cKey}`}
                path={pRoute.key === 'join_session' ? `${pRoute.path}` : `${pRoute.path}/${cRoute.path}`}
                exact={pRoute.exact}
                component={(props) => <cRoute.component {...props} {...componentProps} { ...{ routeName: cRoute.label || 'Unknown' } } />}
              />
            );
          }
          return true;
        });
      } else if (pRoute.allow.includes(userRole)) {
        allowedRoutes.push(
          <Route
            key={pKey}
            path={`${pRoute.path}`}
            exact={pRoute.exact}
            component={(props) => <pRoute.component {...props} {...componentProps}  { ...{ routeName: pRoute.label || 'Unknown' } } />}
          />
        );
      }
      return true;
    });

    // Default route
    let route = (<div>Page Not Found</div>);
    route = (
      <Redirect to={`${this.routePrefix}`} />
    );
    allowedRoutes.push(
      <Route
        key="default"
        exact
        path="/"
        render={() => (route)}
      />
    );
    return allowedRoutes;
  }

  render () {
    // // Routes
    const filteredRoutes = this.filterRoutesList();

    // Classes
    let className = 'app-wrapper app-sidebar-collapsed app-sidebar-fixed app-header-fixed vh-100 app-wrapper-bg-light';
    let classScheme = '';
    classScheme = `${SCHEME_COLOR_COMMOM} ${SCHEME_COLOR_PARTICIPANT}`;
    className = `${classScheme} ${className}`;

    return (
      <Fragment>
        <div className={className} ref={this.appWrapperRef}>
          <Sidebar menus={getMenus('CT-Candidate')} others={{ routePrefix: this.routePrefix, handleRemoveSearchBoxActiveClass: this.handleRemoveSearchBoxActiveClass }}/>
          <div className="app-sidebar-overlay" ref={this.appSidebarOverlayRef} onClick={this.handleAppSidebarOverlayClick}></div>
          <div className="app-main ca-main">
            <PerfectScrollbar id="scrollBarContainer">
              <Switch>{filteredRoutes}</Switch>
            </PerfectScrollbar>
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
}
