export const CT_ADMIN_ROLE = 'CT-Admin';
export const CT_CONSULTANT_ROLE = 'CT-Consultant';
export const CT_CLIENT_ROLE = 'CT-Client';
export const CT_COACH_ROLE = 'CT-Coach';
export const CT_CANDIDATE_ROLE = 'CT-Candidate';
export const CT_ONSITE_AVAILABILITY_ADMIN_ROLE = 'CT-Onsite-Availability-Admin';
export const CT_ONSITE_SUPPORT_ROLE_LOG = 'CT-OnsiteSupport';

export const CA_ADMIN_ROLE = 'CA-Admin';
export const CA_CLIENT_ROLE = 'CA-Client';
export const CA_COACH_ROLE = 'CA-Coach';
export const CA_PARTICIPANT_ROLE = 'CA-Participant';
export const CA_CLIENT_PARTICIPANT_ROLE = 'CA-Client-Participant';
export const CA_INACTIVE_PARTICIPANT_ROLE = 'CA-Inactive-Participant';
export const CA_INACTIVE_CLIENT_ROLE = 'CA-Inactive-Client';

export const CANDIDATE_STATUS_CREATED = 'CREATED';
export const CANDIDATE_STATUS_PENDING = 'PENDING';
export const CANDIDATE_STATUS_DIDNOTENGAGE = 'DID_NOT_ENGAGE';
export const CANDIDATE_STATUS_ACTIVE = 'ACTIVE';
export const CANDIDATE_STATUS_ONHOLD = 'ONHOLD';
export const CANDIDATE_STATUS_COMPLETED = 'COMPLETED';
export const CANDIDATE_STATUS_EXPIRED = 'EXPIRED';
export const CANDIDATE_STATUS_DELETED = 'DELETED';
export const CANDIDATE_STATUS_DEFERRED = 'DEFERRED';

export const ONLINE_MEETING_STATUS_SCHEDULED = 'SCHEDULED';
export const ONLINE_MEETING_STATUS_CANCELLED = 'CANCELLED';
export const ONLINE_MEETING_STATUS_BOOKING_FAILED = 'BOOKING_FAILED';
export const ONLINE_MEETING_STATUS_CANCELLING_FAILED = 'CANCELLING_FAILED';
export const ONLINE_MEETING_STATUS_RETRY = 'RETRY';

export const COACHING_SESSIONS = 'COACHING_SESSIONS';
export const PORTAL_ONLY = 'PORTAL_ONLY';
export const EXPIRED = 'EXPIRED';

export const DAILY = 'DAILY';
export const WEEKLY = 'WEEKLY';
export const MONTHLY = 'MONTHLY';
export const LIMIT_100 = 100;
export const LIMIT_1000 = 1000;

export const ARRAY_OFFLINE_ACTIVITY = ['OFFLINE_ACTIVITY'];
export const ARRAY_CANCELLED = ['CANCELLED'];
export const GAASYNCSCRIPTID = 'ga4AsyncScriptAsync';
export const GASYNCSCRIPTID = 'ga4SyncScriptAsync';

export const DASHBOARD_TO_CALL = 'TO_CALL';
export const DASHBOARD_FIRST_ATTEMPT = 'FIRST_ATTEMPT';
export const DASHBOARD_SECOND_ATTEMPT = 'SECOND_ATTEMPT';
export const DASHBOARD_THIRD_ATTEMPT = 'THIRD_ATTEMPT';
export const DASHBOARD_FINAL = 'FINAL';
export const CONVERSION_CALL_1 = 'Conversion Call 1';
export const CONVERSION_CALL_2 = 'Conversion Call 2';
export const CONVERSION_CALL_3 = 'Conversion Call 3';
export const CONVERSION_OUTREACH = 'Conversion Outreach';
export const CONVERSION_FINAL = 'Final';
export const LAST_PIPELINE_STORE = 'lastPipeline';

export const roles = [
  CT_ADMIN_ROLE,
  CT_CONSULTANT_ROLE,
  CT_CLIENT_ROLE,
  CT_COACH_ROLE,
  CT_CANDIDATE_ROLE,
  CA_ADMIN_ROLE,
  CA_CLIENT_ROLE,
  CA_PARTICIPANT_ROLE,
  CA_COACH_ROLE,
];

export const mappedFileHeader = [
	{ id: 'firstName', title: '(M) First Name' },
	{ id: 'lastName', title: '(M)  Last Name' },
	{ id: 'email', title: ' Personal Email' },
	{ id: 'primaryBranch', title: '(M)  Primary Branch' },
	{ id: 'preferredName', title: ' Preferred Name' },
	{ id: 'gender', title: ' Gender' },
	{ id: 'mobile', title: ' Mobile' },
	{ id: 'workTelNo', title: ' Work Tel No.' },
	{ id: 'workEmail', title: ' Work Email' },
	{ id: 'doNotContactReason', title: ' Do Not Contact Reason' },
	{ id: 'userTags', title: ' User Tags' },
	{ id: 'ownerBranch', title: '(M) Owner Branch' },
	{ id: 'deliveryBranch', title: '(M) Delivery Branch' },
	{ id: 'deliveryRevenueShare', title: '(M) Delivery Revenue Share (%)' },
	{ id: 'product', title: '(M) Product' },
	{ id: 'company', title: '(M) Company' },
	{ id: 'package', title: '(M) Package' },
	{ id: 'primaryConsultant', title: 'Primary Consultant' },
	{ id: 'billingEntity', title: '(M) Billing Entity' },
	{ id: 'invoiceTo', title: '(M) Invoice To' },
	{ id: 'clientContact', title: ' Client Contact' },
	{ id: 'reportReceiver', title: ' Report Receiver' },
	{ id: 'costCentre', title: ' Cost Centre' },
	{ id: 'buCode', title: ' BU code' },
	{ id: 'buName', title: ' BU Name' },
	{ id: 'positionRole', title: ' Position/Role' },
	{ id: 'positionRoleNo', title: ' Position/Role No' },
	{ id: 'positionRoleGrade', title: ' Position/Role Grade' },
	{ id: 'managersName', title: ' Managers Name' },
	{ id: 'managersEmail', title: ' Managers Email' },
	{ id: 'staffNumberAtCompany', title: ' Staff Number at Company' },
	{ id: 'purchaseOrderNumber', title: ' Purchase Order Number' },
	{ id: 'createdOn', title: '(M) Created On (YYYY/MM/DD)' },
	{ id: 'offerOn', title: ' Offer on(YYYY/MM/DD)' },
	{ id: 'offerLetterSent', title: ' Offer Letter Sent' },
	{ id: 'firstScheduledMeeting', title: ' 1st Scheduled Meeting (YYYY/MM/DD)' },
	{ id: 'lastWorkingDay', title: ' Last Working Day (YYYY/MM/DD)' },
	{ id: 'separationInterviewDate', title: ' Separation Interview Date (YYYY/MM/DD)' },
	{ id: 'clientReporting', title: ' (M) Client Reporting' },
	{ id: 'briefNote', title: ' Brief Note' },
	{ id: 'remoteManagement', title: ' Remote Management' },
	{ id: 'portalAccess', title: ' Portal Access' },
	{ id: 'opportunity', title: ' Opportunity' },
	{ id: 'additionalEmployees', title: ' Additional Employees' },
	{ id: 'programTags', title: ' Program Tags' },
	{ id: 'project', title: ' Project' },
];

export const branches = [
	{
		Branches: 'Hudson TM-HK',
		City: 'Hong Kong',
		Country: 'China'
	},
	{
		Branches: 'Hudson TM-ACT',
		City: 'Canberra',
		State: 'Australian Capital Territory; ACT',
		Country: 'Australia'
	},
	{
		Branches: 'Hudson TM-AKL',
		City: 'Auckland',
		Country: 'New Zealand'
	},
	{
		Branches: 'Hudson TM-BJG',
		City: 'Beijing',
		Country: 'China'
	},
	{
		Branches: 'Hudson TM-CH',
		Country: 'China'
	},
	{
		Branches: 'Hudson TM-GWS',
		City: 'Parramatta',
		Country: 'Australia'
	},
	{
		Branches: 'Hudson TM-GZ',
		City: 'Guangzhou',
		Country: 'China'
	},
	{
		Branches: 'Hudson TM-HCC',
		City: 'Newcastle',
		State: 'New South Whales; NSW',
		Country: 'Australia'
	},
	{
		Branches: 'Hudson TM-NSW',
		State: 'New South Whales; NSW',
		Country: 'Australia'
	},
	{
		Branches: 'Hudson TM-QLD',
		State: 'Queensland; QLD',
		Country: 'Australia'
	},
	{
		Branches: 'Hudson TM-SA',
		State: 'South Australia; SA',
		Country: 'Australia'
	},
	{
		Branches: 'Hudson TM-SCO',
		City: 'Scoresby',
		State: 'Victoria; VIC',
		Country: 'Australia'
	},
	{
		Branches: 'Hudson TM-SHG',
		City: 'Shanghai',
		Country: 'China'
	},
	{
		Branches: 'Hudson TM-SNG',
		City: 'Singapore',
		Country: 'Singapore'
	},
	{
		Branches: 'Hudson TM-UK',
		Country: 'United Kingdom'
	},
	{
		Branches: 'Hudson TM-USA',
		Country: 'United States'
	},
	{
		Branches: 'Hudson TM-VIC',
		State: 'Victoria; VIC',
		Country: 'Australia'
	},
	{
		Branches: 'Hudson TM-WA',
		State: 'Western Australia; WA',
		Country: 'Australia'
	},
	{
		Branches: 'Hudson TM-WEL',
		City: 'Wellington',
		Country: 'New Zealand'
	}
];

export const packages = {
	'Job Search (3 sessions)': {
		AUD: 'Advance - Job Search (3) AUD',
		NZD: 'Advance - Job Search (3) NZD',
		SGD: 'Advance - Job Search (3) SGD',
		OTHER: 'Advance - Job Search (3)'
	},
	'Essentials (6 sessions)': {
		AUD: 'Advance - Essentials (6) AUD',
		NZD: 'Advance - Essentials (6) NZD',
		SGD: 'Advance - Essentials (6) SGD',
		OTHER: 'Advance - Essentials (6)'
	},
	'Focus (12 sessions)': {
		AUD: 'Advance - Focus (12) AUD',
		NZD: 'Advance - Focus (12) NZD',
		SGD: 'Advance - Focus (12) SGD',
		OTHER: 'Advance - Focus (12)'
	},
	'Exploration (18 sessions)': {
		AUD: 'Advance - Exploration (18) AUD',
		NZD: 'Advance - Exploration (18) NZD',
		SGD: 'Advance - Exploration (18) SGD',
		OTHER: 'Advance - Exploration (18)'
	},
	'Executive (24 sessions)': {
		AUD: 'Advance - Executive (24) AUD',
		NZD: 'Advance - Executive (24) NZD',
		SGD: 'Advance - Executive (24) SGD',
		OTHER: 'Advance - Executive (24)'
	}
};

export const fullCountriesName = {
	AU: 'Australia',
	NZ: 'New Zealand',
	SG: 'Singapore',
	IN: 'India',
	MY: 'Malaysia',
	ID: 'Indonesia',
	JP: 'Japan',
	TW: 'Taiwan',
	VN: 'Vietnam',
	CN: 'China',
	HK: 'Hong Kong',
	GB: 'United Kingdom',
	US: 'United States',
	PH: 'Philippines',
	TH: 'Thailand'
};

export const eventTypeList = {
  CandidateNotesCreated: 'com.hudson.ct.CandidateNotesCreated',
  CandidateUpdated: 'com.hudson.ct.CandidateUpdated',
  CandidateStatusChanged: 'com.hudson.ct.CandidateStatusChanged',
  CandidateUpdatedAndStatusChanged: 'com.hudson.ct.CandidateUpdatedAndStatusChanged',
  CandidateDeleted: 'com.hudson.ct.CandidateDeleted',
  SessionCancelled: 'com.hudson.ct.SessionCancelled'
};

export const eventList = {
  [eventTypeList.CandidateNotesCreated]: 'added action',
  [eventTypeList.CandidateUpdated]: 'updated candidate profile',
  [eventTypeList.CandidateStatusChanged]: 'changed candidate status to :v',
  [eventTypeList.CandidateUpdatedAndStatusChanged]: 'updated candidate profile and changed status to :v',
  [eventTypeList.CandidateDeleted]: 'deleted',
  [eventTypeList.SessionCancelled]: 'canceled the session occurring at :bookedTime on :bookedDate with :coachName (Coach)'
};

export const lastRoleIndustryOptions = [
	{ label: 'Aeorspace and Defence', value: 'Aeorspace and Defence' },
	{ label: 'Automotive', value: 'Automotive' },
	{ label: 'Chemical', value: 'Chemical' },
	{ label: 'Construction', value: 'Construction' },
	{ label: 'Energy and Utilities', value: 'Energy and Utilities' },
	{ label: 'Engineering', value: 'Engineering' },
	{ label: 'Financial Services', value: 'Financial Services' },
	{ label: 'FMCG', value: 'FMCG' },
	{ label: 'Industry and Manufacturing', value: 'Industry and Manufacturing' },
	{ label: 'Insurance', value: 'Insurance' },
	{ label: 'IT', value: 'IT' },
	{ label: 'Media and Internet', value: 'Media and Internet' },
	{ label: 'Pharm. and Healthcare', value: 'Pharm. and Healthcare' },
	{ label: 'Professional Services', value: 'Professional Services' },
	{ label: 'Property and Realestate', value: 'Property and Realestate' },
	{ label: 'Public Sector', value: 'Public Sector' },
	{ label: 'Retail', value: 'Retail' },
	{ label: 'Telecommunications', value: 'Telecommunications' },
	{ label: 'Not for Profit', value: 'Not for Profit' },
];

export const lastRoleFunctionOptions = [
	{ label: 'Accounting or Finance', value: 'Accounting or Finance' },
	{ label: 'Admin. or Business Support', value: 'Admin. or Business Support' },
	{ label: 'Banking', value: 'Banking' },
	{ label: 'Construction or Property', value: 'Construction or Property' },
	{ label: 'Customer Service', value: 'Customer Service' },
	{ label: 'Engineering', value: 'Engineering' },
	{ label: 'Human Resources', value: 'Human Resources' },
	{ label: 'Information Technology', value: 'Information Technology' },
	{ label: 'Law Enforcement', value: 'Law Enforcement' },
	{ label: 'Legal', value: 'Legal' },
	{ label: 'Manufacturing', value: 'Manufacturing' },
	{ label: 'Marketing, or Communications', value: 'Marketing, or Communications' },
	{ label: 'Medical or Health', value: 'Medical or Health' },
	{ label: 'Supply Chain, Procurement/Dist.', value: 'Supply Chain, Procurement/Dist.' },
	{ label: 'Research & Development', value: 'Research & Development' },
	{ label: 'Sales', value: 'Sales' },
	{ label: 'Technical or Operations', value: 'Technical or Operations' },
	{ label: 'Do not wish to disclose', value: 'Do not wish to disclose' }
];

export const specialties = [
	'Resume',
	'Interview',
	'Personal Brand',
	'Career Change',
	'Career Exploration',
	'Well Being & Resilience',
	'Managing Change',
	'Personal Value Proposition',
	'Retirement Transition',
	'Executive Coaching',
	'Networking',
	'LinkedIn Optimisation',
	'Presentation Skills',
	'Recruitment & Search Firms',
	'Business Startup'
];

export const typeCoach = [
	{ label: 'COACH', value: 'COACH' }
];

export const colourStyles = {
	indicatorSeparator: () => ({
		display: 'none',
	}),
	container: () => ({
		position: 'relative'
	}),
	control: (styles, { isFocused, isSelected, isDisabled }) => ({
		...styles,
		minHeight: '3rem',
		boxShadow: 'none',
		background: isDisabled ? '#FFFFFF' : '',
		borderColor: isFocused ? '#E5E5E5' : '#E5E5E5',
		'&:hover': {
			borderColor: styles.isFocused ? '#E5E5E5' : '#E5E5E5'
		}
	}),
	input: (styles) => ({
		...styles,
		'& input': {
			backgroundColor: 'transparent !important',
			padding: '0 !important'
		}
	}),
	menu: (styles, { isFocused }) => ({
		...styles,
		width: 'auto',
		minWidth: '100%',
		marginTop: '0.25rem',
		zIndex: 999,
		'& .Button-AddNewClient': {
			width: '100%',
			padding: '1rem'
		}
	}),
	menuList: (styles, { isFocused }) => ({
		...styles,
		background: '#FFFFFF',
		borderRadius: '0px 0px 4px 4px !important',
		borderBottom: '1px solid #E5E5E5',
		padding: '0.5rem'
	}),
	option: (styles, { data, isFocused, isSelected }) => ({
		...styles,
		display: 'flex',
		justifyContent: 'space-between',
		padding: data.value === '' ? '1.25rem' : '0.75rem',
    alignItems: 'center',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '0.875rem',
		whiteSpace: 'nowrap',
		color: '#1E2C42',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		backgroundColor: isFocused || isSelected ? '#FAFAFA' : 'white',
		'&:hover, & label:hover': {
			cursor: 'pointer'
		},
		'&:active': {
			backgroundColor: '#E5E5E5'
		},
		'& span.MuiCheckbox-root': {
			padding: 0,
			marginLeft: 'auto'
		},
		'& label': {
			marginBottom: 0,
			paddingBottom: 0
		}
	}),
	singleValue: (styles) => ({
		...styles,
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '0.875rem'
	}),
	valueContainer: (styles) => ({
		...styles,
		padding: '0.25rem 0.25rem 0.25rem 1rem'
	}),
	placeholder: (styles) => ({
		...styles,
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '0.875rem',
		color: '#1E2C42',
		overflow: 'hidden',
    whiteSpace: 'nowrap'
	}),
	multiValue: (styles) => ({
		...styles,
		background: '#E5E5E5',
		borderRadius: '5px',
		fontWeight: 'normal',
		fontSize: '0.875rem',
		margin: '0.25rem',
		'& .CloseIcon': {
			margin: '0'
		},
		'& div': {
			fontSize: 'inherit',
			color: '#1E2C42',
			padding: '0.25rem'
		}
	}),
	multiValueRemove: (styles) => ({
		...styles,
		'&:hover': {
			background: 'none'
		}
	}),
	groupHeading: (styles) => ({
    ...styles,
		marginBottom: '0.5em',
		'& + div': {
			paddingLeft: '1rem'
		}
  })
};

export const productTypeCode = {
	coachingSession: 'COACHING_SESSIONS'
};

export const landingTileCode = {
	bookACoach: 'Book a Coach',
	personalInsights: 'Personal Insights',
	skillUp: 'Skill Up'
};

export const candidateTaskTitle = {
	completeProfile: 'Complete your profile',
	bookCoachingSession: 'Book a coaching session',
	[landingTileCode.bookACoach]: 'Book a coaching session',
	checkAmazingContent: 'Check out our amazing content',
	useJobTrackerToApplyJob: 'Apply for a job using Job Tracker',
	buildResume: 'Build your resume',
	uncoverPersonality: 'Uncover your personality',
	[landingTileCode.personalInsights]: 'Uncover your personality',
	understandTransferableSkills: 'Understand your transferable skills'
};

export const candiateBookSessionStatus = {
	success: 'Scheduled',
	fail: 'Failed to book',
	await: 'To Book'
};

export const sessionStatus = {
	booked: 'BOOKED',
	available: 'AVAILABLE'
};
