import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { Input } from 'reactstrap';
import Color from '../../../Constants/Color';
import Icon from '../Icon';
import './custom.scss';

const Search = ({ initialValue, onChange, onKeyUp }) => {
  const [checked, setChecked] = useState(true);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleSearch = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  const handleSwitch = (chk) => {
    setChecked(chk);
  };
  const handleOnKeyUp = (e) => {
    if (e.key === 'Enter') {
      onKeyUp();
    }
  };

  return (
    <div className="table-search-box">
      <div className="search-wrapper">
        <label className="icon-wrapper text-black" htmlFor="search-table-input">
          <Icon
            name="faSearch"
            className="cursor-pointer"
          />
        </label>
        <Input
          className="form-control"
          id="search-table-input"
          placeholder="Search"
          type="search"
          value={value || ''}
          onChange={handleSearch}
          onKeyPress={handleOnKeyUp}
        />
      </div>
      <div className="wrapper-switch">
        <label className="ml-4 mr-4"></label>
        <Switch
          checked={checked}
          onChange={handleSwitch}
          onColor={Color.Emerald}
          offColor={Color.Background}
          onHandleColor={Color.White}
          offHandleColor={Color.White}
          handleDiameter={22}
          height={24}
          width={48}
          uncheckedIcon={null}
          checkedIcon={null}
          className="btn-switch"
        />
        <label className="ml-2">Smart match use <span className="profile">Profile</span></label>
      </div>
    </div>
  );
};

export default Search;
