import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import './custom.scss';

export default function CAButton (props) {
  const {
    solid = false,
    salmon = false,
    link = false,
    isDisabled = false,
    path = '/',
    iconName = '',
    className = '',
    wrapperClassName = '',
    label = '',
  } = props;
  const solidClass = solid ? 'btn-solid' : '';
  const salmonClass = salmon ? 'btn-salmon' : '';
  const classes = `btn-pill ${className} ${solidClass} ${salmonClass} ca-button`;
  const wrapperClasses = `d-inline-block ${wrapperClassName}`;

  const btnRef = useRef(null);

  const handleClick = () => {
    // if (!btnRef.current.isDisabled && onClick) {
    //   if (isAutoDisabled) {
    //     btnRef.current.isDisabled = true;
    //   }
      props.onClick({ btnRef: btnRef.current });
    // }
  };

  // To render as a button
  const renderButton = () => (
    <Button
      ref={btnRef}
      color="primary"
      className={`d-flex align-items-center justify-content-center ${classes}`}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {(iconName && iconName !== '') && <Icon name={iconName} className="mr-2" />}
      <span>{label || props.children}</span>
    </Button>
  );

    // To render as a link
  const renderLink = () => (
    <Link
      ref={btnRef}
      color="primary"
      className={`d-flex align-items-center justify-content-center ${classes}`}
      to={path}
    >
      {(iconName && iconName !== '') && <Icon name={iconName} className="mr-2" />}
      <span>{label || props.children}</span>
    </Link>
  );

  return (
    <div className={wrapperClasses}>
      {link ? renderLink() : renderButton()}
    </div>
  );
}
