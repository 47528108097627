import React, {
    useState, useRef, useEffect, Fragment
} from 'react';
import { Table } from 'reactstrap';
import { chunk } from 'lodash';
import { getSortedData } from '../../../helper/helper';
import Icon from '../Icon';

function usePrevious (value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default function InfoTable (props) {
    const {
        fontSize, fields, data, rowsPerPage, page, updateState, className, fontSizeHeader, sortOption
    } = props;
    const [, setChunkData] = useState([]);
    const [pageData, setPageData] = useState([]);
    const [sort, setSort] = useState({ order: null, orderBy: null });
    const [dataCount, setDataCount] = useState(data.length);
    const [isSorted] = useState(fields.find((f) => f.sort));
    const previousDataCount = usePrevious(dataCount);

    useEffect(() => {
        setDataCount(data.length);

        // For list candidate sort
        if (sortOption) {
            setSort(sortOption);
        }

        if (data.length !== previousDataCount && isSorted) {
            const defaultSortField = fields.find((f) => f.defaultSort);
            if (sort.order === null || sort.orderBy === null || defaultSortField) {
                if (defaultSortField) {
                    const { sortedData } = getSortedData(data, fields, null, { order: defaultSortField.defaultSortOrder ? defaultSortField.defaultSortOrder : 'asc', orderBy: defaultSortField.name });
                    const sortedChunkData = chunk(sortedData, rowsPerPage);
                    setChunkData(sortedChunkData);
                    setPageData(sortedChunkData[page]);
                    updateState(sortedData);
                    setSort({ order: defaultSortField.defaultSortOrder ? defaultSortField.defaultSortOrder : 'asc', orderBy: defaultSortField.name }); // to do
                } else {
                    const sortedChunkData = chunk(data, rowsPerPage);
                    setChunkData(sortedChunkData);
                    setPageData(sortedChunkData[page]);
                    updateState(data);
                }
            } else {
                const { sortedData } = getSortedData(data, fields, null, sort);
                const sortedChunkData = chunk(sortedData, rowsPerPage);
                setChunkData(sortedChunkData);
                setPageData(sortedChunkData[page]);
                updateState(sortedData);
            }
        } else {
            const newChunkData = chunk(data, rowsPerPage);
            setChunkData(newChunkData);
            setPageData(newChunkData[page]);
        }
    // eslint-disable-next-line
    }, [data, data.length, page])

    function renderSortIcon (name) {
        const { orderBy, order } = sort;
        if (orderBy === name) {
            if (order === 'asc') {
                return 'faSortUp';
            }
            return 'faSortDown';
        }
        return 'faSort';
    }

    function renderSortedTableHeader (field) {
        const header = field.center ? (<div className='d-flex align-content-center justify-content-center'>
            <div>
                <a
                    href="#/"
                    onClick={() => { sortHandler(field.name); }}
                >
                    {field.name}
                </a>
                &nbsp;
                <Icon name={renderSortIcon(field.name)} />
            </div>
        </div>) : (<Fragment>
            <a
                href="#/"
                onClick={() => { sortHandler(field.name); }}
            >
                {field.name}
            </a>
            &nbsp;
            <Icon name={renderSortIcon(field.name)} />
        </Fragment>);
        return header;
    }

    function sortHandler (name) {
        const orderBy = name;
        const { sortedData, order } = getSortedData(data, fields, name, sort);
        setSort({ orderBy, order });
        const sortedChunkData = chunk(sortedData, rowsPerPage);
        setChunkData(sortedChunkData);
        setPageData(sortedChunkData[page]);
        updateState(sortedData, { orderBy, order });
    }

    return (
        <Table hover style={{ fontSize }} className={className || ''}>
            <thead>
                <tr>
                    {
                        fields.map((field, index) => (
                            <th key={index} style={field.width ? { width: `${field.width}%`, fontSize: fontSizeHeader } : null}
                                className={field.className ? `border-0 fixed-header ${field.className}` : 'border-0 fixed-header'} >
                                {field.sort ? renderSortedTableHeader(field) : field.name}
                                {field.headerIcon}
                            </th>
                        ))
                    }
                </tr>
            </thead>
            <tbody>
            {
                data.length === 0 || !pageData ? (
                    <tr>
                        <td className="border-0 text-center font-size-sm" colSpan={fields.length + 3}>No Data</td>
                    </tr>
                ) : pageData.map((row, key) => (
                    <tr key={key} className={`${row.hover ? 'hover' : ''}`}>
                        {
                            fields.map((field, kField) => (
                                <td key={kField} style={field.width_td ? { maxWidth: `${field.width_td}rem` } : null} className="text-left">
                                    { field.render(row, key) }
                                </td>
                            ))
                        }
                    </tr>
                ))
            }
            </tbody>
        </Table>
    );
}
