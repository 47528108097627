import React from 'react';
import { ClipLoader } from 'react-spinners';

export default function Spinner () {
  return (
    <div className="d-flex align-items-center justify-content-center flex-grow-1" style={{ zIndex: 9999 }}>
      <ClipLoader color="var(--primary)" size="2.5rem" />
    </div>
  );
}
