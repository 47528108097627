/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:eab998d4-3f3d-4d46-ad6a-625ded078c3c",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_QKrflvvCB",
    "aws_user_pools_web_client_id": "6u29381i7jsvm43ahcdekn6m7f",
    "oauth": {},
    "aws_user_files_s3_bucket": "mobilityapplicationstorage144508-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://uu524ng3wra3zkg2wme6vscg6i.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
