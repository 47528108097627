/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
import React, { Component, Fragment, createRef } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { Row, Col } from 'reactstrap';
import Dropzone from 'react-dropzone';
import Header from '../Common/Header';
import ImageText from '../Common/ImageText';
import ErrorBoundary from '../Common/ErrorBoundary';
import { postTokenJobs, postSearchJobs } from '../../graphql/mutations';
import './custom.scss';
import { ModalSteps } from '../Common/Modal';
import Icon from '../Common/Icon';
import CAButton from '../Common/Button';
import folderUpload from '../../assets/images/folder_upload.png';
import { listGlobalConfigs } from '../../graphql/queries';
import { notify } from '../../helper/helper';
import {
  cardList, initconfigModal, LocationMapping, SkillsMatch
} from './constant';
import folderExistsFile from '../../assets/images/folder_exists_file.svg';
import folderEmpty from '../../assets/images/folder_empty.svg';

class Dashboard extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isApiCall: true,
      searchText: '',
      isDisabledUpload: false,
      content: {
        data: [],
        error: [],
        fileName: '',
        fileData: null,
      },
      dataJobsMatching: [],
      configModal: initconfigModal,
      queryParts: [],
    };
  }

  dropzoneRef = createRef();

  folder = createRef();

  openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (this.dropzoneRef.current) {
      this.dropzoneRef.current.open();
    }
  };

  getTokenJob = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(listGlobalConfigs, {
          filter: {
            key: {
              eq: 'session_id',
            },
          },
        })
      );
      if (result.data.listGlobalConfigs.items.length !== 0) {
        const sessionID = result.data.listGlobalConfigs.items[0].value;
        const res = await API.graphql(
          graphqlOperation(postTokenJobs, {
            data: JSON.stringify({
              sessionID,
            }),
          })
        );
        const data = JSON.parse(res.data?.postTokenJobs);
        if (data.statusCode === 200) {
          return JSON.parse(data.data);
        }
      }
    } catch (error) {
      console.error('the getTokenJob function occur an error: ', error);
    }
    return '';
  }

  parseSkillsMatch = (job) => {
    if (!job || !this.state.queryParts) {
      return;
    }

    const skillsMatch = [];
    const skillsNotMatch = [];
    const queryPart = this.state.queryParts;
    const queryPartScrore = job.queryPartScores;

    SkillsMatch.map((item) => {
      queryPart.map((val, idx) => {
        if (val.field === item.key) {
          const curPos = queryPartScrore[idx];
          const curItem = val.items[0];
          const skill = {
            label: '',
            value: curPos === 0 ? 0 : 1
          };
          if (curItem.subParts && item.key !== 'workfield') {
            let subLable = `${item.label} `;
            curItem.subParts.map((p) => {
              subLable += `${p.fieldLabel}: ${p.items[0].label} `;
            });
            skill.label = subLable;
          } else {
            skill.label = `${item.label} ${curItem.label ? curItem.label : ''}`;
          }

          if (curPos === 1) {
            skillsMatch.push(skill);
          } else {
            skillsNotMatch.push(skill);
          }
        }
      });
    });
    return skillsMatch.concat(skillsNotMatch);
  }

  // Get search value from Search
  getSearchValue = (value) => {
    this.setState({ searchText: value });
  };

  handleOnClick = async (e) => {
    if (!this.state.content.fileName) {
      return;
    }
    const tmpConfigModal = { ...initconfigModal };
    tmpConfigModal.data.activeStep = 1;
    tmpConfigModal.data.buttons[0].isDisabled = true;
    this.setState({
      ...this.state,
      configModal: {
        ...initconfigModal,
        modalIsOpen: true,
      },
    });
    try {
      // start upload file to s3
      const rest = await Storage.put(
        this.state.content.fileName,
        this.state.content.fileData
      );
      if (rest) {
        const reqToken = await this.getTokenJob();
        if (reqToken) {
          const currentState = this.state;
          currentState.configModal.data.activeStep = 2;
          currentState.configModal.data.steps[1] = {
            ...currentState.configModal.data.steps[1],
            render: <Icon solid name="faCheck" size="lg" className="mt-2" />,
          };
          this.setState({
            ...currentState,
          });

          // Start Call Search Job using lambda
          const searchJobsResult = await API.graphql(
            graphqlOperation(postSearchJobs, {
              data: JSON.stringify({
                fileName: this.state.content.fileName,
                accessToken: reqToken,
              }),
            })
          );

          const searchObj = JSON.parse(searchJobsResult.data.postSearchJobs);
          if (searchObj.statusCode === 200) {
            const nState = this.state;
            nState.configModal.data.buttons[0].solid = true;
            nState.configModal.data.buttons[0].isDisabled = false;
            nState.configModal.data.activeStep = 3;
            nState.configModal.data.steps[2] = {
              ...nState.configModal.data.steps[2],
              render: <Icon solid name="faCheck" size="lg" className="mt-2" />,
            };
            const jobParse = JSON.parse(searchObj.data);
            console.log(jobParse);
            nState.queryParts = jobParse.queryParts;
            nState.dataJobsMatching = jobParse.resultItems;
            this.setState({
              ...nState,
            });
          } else {
            notify('Something went wrong, Please check your SessionID!');
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  handleJobsMatchingData = (data) => {
    const dataTable = [];
    const locationList = [];
    Promise.all(data.map((item) => {
      const newItem = {};
      newItem.Skills = this.parseSkillsMatch(item);
      newItem.id = item.docID;
      newItem.Title = (item.fieldValues.jobtitle[0]?.value || '').replace(/(<([^>]+)>)/ig, '');
      // check the job type is part-time or full time or both
      // let jobType = item.fieldValues.employment_type_code[0]?.value || '1';
      newItem.Type = (item.fieldValues.employment_type_code[0]?.value || '').replace(/(<([^>]+)>)/ig, '');
      // contract type
      // let contractType = item.fieldValues.contract_type_code[0]?.value || '';
      newItem.Contract = (item.fieldValues.contract_type_code[0]?.value || '').replace(/(<([^>]+)>)/ig, '').replace('contract', '');
      // get name of company
      newItem.CompanyName = (item.fieldValues.org_normalized_name[0]?.value || '').replace(/(<([^>]+)>)/ig, '');
      const percentMatched = (item.queryPartScores.reduce((pv, cv) => pv + cv, 0) / item.queryPartScores.length) * 100;
      newItem.PercentMatched = percentMatched;
      // score = matched / total
      const score = newItem.Skills.filter((i) => i.value === 1).length / newItem.Skills.length;
      if (score > 0.75) {
        newItem.Score = 100;
      } else {
        newItem.Score = Math.round((score + 0.25) * 100);
      }
      // newItem.Score = newItem.Skills.filter((i) => i.value == 1).length / newItem.Skills.length;

      newItem.ItemMatched = item.queryPartScores.reduce((pv, cv) => pv + cv, 0);
      newItem.Description = item.fieldValues.job_description[0]?.value || '';
      // handle IT skills :
      let itSkills = '';
      item.fieldValues.compskills.map((i) => {
        itSkills = `${i.value.replace(/(<([^>]+)>)/ig, '')},`;
      });
      newItem.ItSkills = itSkills.slice(0, -1);
      // salary
      newItem.Salary = item.fieldValues.salary[0]?.value || '';

      newItem.EducationLevel = (item.fieldValues.educationlevel_international[0]?.value || '').replace(/(<([^>]+)>)/ig, '');
      newItem.Profession = item.fieldValues.profession_code[0]?.value || '';
      const minimumYears = item.fieldValues.experience_years[0].subValues.minimum[0] || '';
      const maximumYears = item.fieldValues.experience_years[0].subValues.maximum[0] || '';
      let experienceYears = '';
      if (minimumYears !== '') {
        experienceYears = `${minimumYears} - `;
      }
      if (maximumYears !== '') {
        experienceYears += maximumYears;
      } else {
        experienceYears = experienceYears.slice(0, -3);
      }
      newItem.ExperienceYears = experienceYears;
      newItem.AdvertiserType = item.fieldValues.org_advertiser_type[0].value === '1' ? 'Direct Employer' : 'Staffing / Recruitment Agency';
      // handle to replace locations
      const tmpLocation = (item.fieldValues.city[0]?.value || '').replace(/(<([^>]+)>)/ig, '').trim();
      const validLocation = LocationMapping.find((i) => i.value === tmpLocation);
      if (validLocation) {
        newItem.Location = validLocation.label;
      } else {
        newItem.Location = 'Melbourne';
      }
      // get the list of locations for the filter
      if (newItem.Location !== '') {
        // check duplicate location
        const locationDuplicate = locationList.find((i) => i.label === newItem.Location);
        if (!locationDuplicate) {
          const newLocation = { label: newItem.Location, value: newItem.Location };
          locationList.push(newLocation);
        }
      }
      // sort list with alphabetically
      locationList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      // Language Skills
      let languageSkills = '';
      item.fieldValues.langskills_iso.map((i) => {
        languageSkills = `${i.value.replace(/(<([^>]+)>)/ig, '')},`;
      });
      newItem.LanguageSkills = languageSkills.slice(0, -1);
      // CurrentWorkField
      newItem.CurrentWorkField = (item.fieldValues.workfield[0].subValues.workfield[0] || '').replace(/(<([^>]+)>)/ig, '');
      dataTable.push(newItem);
    }));
    const { history } = this.props;
    const { queryParts, content } = this.state;
    history.push({
      pathname: 'job-matching',
      state: {
        locationList,
        dataTable,
        queryParts,
        fileName: content.fileName
      }
    });
  }

  mappingEducationNumber = (numberEducation) => {
    let educationLevel = '';
    switch (numberEducation) {
      case '3':
        educationLevel = 'Bachelor';
        break;
      case '4':
        educationLevel = 'Master';
        break;
      default:
        break;
    }
    return educationLevel;
  }

  handleOnDragEnter = (e) => {
    e.preventDefault();
    this.folder.current.style.transform = 'scale(1.8)';
  };

  handleOnDragLeave = (e) => {
    e.preventDefault();
    this.folder.current.style.transform = 'scale(1)';
  };

  handleOnDrop = async (file) => {
    const { content } = this.state;
    // check ondrop file
    if (file.length > 0) {
      this.setState({
        isDisabledUpload: false,
        content: {
          ...this.state.content,
          data: ['12'],
          fileName: file[0].name,
          fileData: file[0],
        },
      });
    } else {
      this.setState({
        ...content,
        data: [],
        error: ['Just support upload one file'],
      });
    }
  };

  closeModal = () => {
    this.setState({
      ...this.state,
      configModal: initconfigModal,
    });
  };

  onShowJobsList = () => {
    const { dataJobsMatching } = this.state;
    this.handleJobsMatchingData(dataJobsMatching);
  };

  renderCandidateDashboard = () => {
    const { content, isDisabledUpload } = this.state;
    return (
      <div className='ca-content d-flex flex-row'>
        <Row className="w-100 mx-0 container-dashboard">
          <Col xs={12} md={8} className="height-auto">
            <Row className="d-flex my-3">
              {cardList.slice(0, 3).map((item, index) => (
                <Col key={index} xs={12} md={4} className="card-wrapper">
                  <ImageText {...item} />
                </Col>
              ))}
            </Row>
            <Row className="d-flex flex-wrap my-4">
              {cardList.slice(3, 6).map((item, index) => (
                <Col key={index} xs={12} md={4} className="card-wrapper">
                  <ImageText {...item} />
                </Col>
              ))}
            </Row>
          </Col>
          <Col
            xs={12}
            md={4}
            className="height-auto py-3 d-flex justify-content-flex-end"
          >
            <div className="resume-wrapper d-flex flex-column">
              <div className="align-items-center d-flex justify-content-center">
                <Dropzone
                  accept=".pdf"
                  onDrop={(file) => this.handleOnDrop(file)}
                  multiple={false}
                  noClick
                  noKeyboard
                  ref={this.dropzoneRef}
                  onDragEnter={(e) => this.handleOnDragEnter(e)}
                  onDragLeave={(e) => this.handleOnDragLeave(e)}
                >
                  {({ getRootProps, getInputProps, open: browse }) => (
                    <div {...getRootProps()} className="resume-upload-wrapper">
                      <input {...getInputProps()} />
                      <div className="upload-container w-100 rounded-sm position-relative text-center">
                        <Fragment>
                          <img
                            ref={this.folder}
                            className="mw-100 image-resume"
                            src={content.fileName ? folderExistsFile : folderEmpty}
                            alt="default bulk"
                          />
                          <p>Drag and Drop your resume <br />here or <span onClick={this.openDialog}>browse file</span></p>
                          {content.data.length > 0 ? (
                            <div className="file-name">{content.fileName}</div>
                          ) : null}
                        </Fragment>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              <div className="title mt-4">Find amazing job matches</div>
              <div className="description mt-2">
                Add your resume to see matched job opportunities - both internal and external
              </div>
              <div className="align-items-center d-flex justify-content-center flex-grow-1">
                <CAButton
                  onClick={this.handleOnClick}
                  isDisabled={isDisabledUpload}
                  solid={true}
                >
                  Start Matching
                </CAButton>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  render () {
    const headerProps = { ...this.props, getSearchValue: this.getSearchValue };
    return (
      <Fragment>
        <ErrorBoundary>
          <Header {...headerProps} />
        </ErrorBoundary>
        <ErrorBoundary>
          {this.renderCandidateDashboard()}
        </ErrorBoundary>
        <ModalSteps
          {...this.state.configModal}
          className="modal-normal"
          onToggle={this.closeModal}
          onShowJobsList={this.onShowJobsList}
        />
      </Fragment>
    );
  }
}

export default Dashboard;
