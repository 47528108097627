/* eslint-disable react/react-in-jsx-scope */

export const SkillsMatch = [
  { key: 'compskills', label: 'IT Skills:' },
  { key: 'educationlevel_international', label: 'Education ' },
  { key: 'experience_years', label: 'Years of Experience:' },
  { key: 'jobtitle', label: 'Position: ' },
  { key: 'langskills_iso', label: 'Language Skills: ' },
  { key: 'workfield', label: 'Current Work Field' },
  { key: 'FULLTEXT', label: '' },
];

export const JobTypeMapping = [
  { value: '1', label: 'Full-time' },
  { value: '2', label: 'Part-time' },
  { value: '3', label: 'Part-time/Full-time' },
];

export const ContractTypeMapping = [
  { value: '1', label: 'Permanent' },
  { value: '2', label: 'Temporary' },
  { value: '3', label: 'Possibly permanent' },
  { value: '4', label: 'Secondment/ Interim' },
  { value: '5', label: 'Franchise' },
  { value: '6', label: 'Side job' },
  { value: '7', label: 'Internship / Graduation position' },
  { value: '8', label: 'Voluntary work' },
  { value: '9', label: 'Freelance' },
  { value: '10', label: 'Apprenticeship' },
];

export const LocationMapping = [
  { value: 'Aberdeen', label: 'Melbourne' },
  { value: 'Grand Prairie', label: 'Melbourne' },
  { value: 'Irvine', label: 'Melbourne' },
  { value: 'Lancashire', label: 'Melbourne' },
  { value: 'London', label: 'Sydney' },
  { value: 'Malvern', label: 'Sydney' },
  { value: 'Manchester', label: 'Sydney' },
  { value: 'Northampton', label: 'Brisbane' },
  { value: 'Reston', label: 'Brisbane' },
  { value: 'Sheffield', label: 'Brisbane' },
  { value: 'Melbourne', label: 'Melbourne' },
  { value: 'Sydney', label: 'Sydney' },
  { value: 'Brisbane', label: 'Brisbane' },
];
