import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

export default function Icon (props) {
  const {
    color, name, onClick, id,
    className, style, size, regular,
    brand, wrapperClass = ''
  } = props;
  let icon = fas[name];
  if (regular) {
    icon = far[name];
  } else if (brand) {
    icon = fab[name];
  }
  if (!icon) {
    // default icon
    icon = fas.faBan;
  }
  return (
    <div className={`d-inline-flex align-items-center ${wrapperClass}`} onClick={onClick || null}>
      <FontAwesomeIcon
        id={id || ''}
        icon={icon}
        color={color}
        size={size || '1x'}
        className={className || ''}
        style={style || ''}
      />
    </div>
  );
}
