/* eslint-disable react/react-in-jsx-scope */
import imgJobTracker from '../../assets/images/job_tracker.png';
import imgCareerInsight from '../../assets/images/career_insights.png';
import imgResumeBuilder from '../../assets/images/resume_builder.png';
import imgBookACoach from '../../assets/images/book_coach.png';
import imgPersonalInsight from '../../assets/images/personal_insights.png';
import imgSkillUp from '../../assets/images/skill_up.png';
import Icon from '../Common/Icon';

export const cardList = [
  {
    title: 'Skill Up',
    image: imgSkillUp,

    description:
      'Learn from an extensive library designed to help you stand out',
    linkTo: '#',
  },
  {
    title: 'Personal insights',
    image: imgPersonalInsight,
    description:
      'Uncover your personality orientation through a short interview online',
    linkTo: '#',
  },
  {
    title: 'Book a coach',
    image: imgBookACoach,
    description: 'Book a coaching session with an experienced career coach',
    linkTo: '#',
  },
  {
    title: 'Resume builder',
    image: imgResumeBuilder,
    description: 'Create an impressive resume within a few clicks',
    linkTo: '#',
  },
  {
    title: 'Career insights',
    image: imgCareerInsight,
    description:
      'Understand your transferable skill-set and other industries where they are desired',
    linkTo: '#',
  },
  {
    title: 'Job tracker',
    image: imgJobTracker,
    description:
      'Keep track of your job applications, progress, contacts and deadlines',
    linkTo: '#',
  },
];

export const initconfigModal = {
  modalIsOpen: false,
  loading: true,
  data: {
    activeStep: 1,
    steps: [
      {
        label: 'You role',
        render: <Icon solid name="faCheck" size="lg" className="mt-2" />,
      },
      {
        label: 'Resume Breakdown',
      },
      {
        label: 'Tailored job matches',
      },
    ],
    buttons: [
      {
        value: 1,
        solid: false,
        isDisabled: false,
        label: 'Show me job matches',
      },
    ],
  },
};

export const SkillsMatch = [
  { key: 'compskills', label: 'IT Skills:' },
  { key: 'educationlevel_international', label: 'Education ' },
  { key: 'experience_years', label: 'Years of Experience:' },
  { key: 'jobtitle', label: 'Position: ' },
  { key: 'langskills_iso', label: 'Language Skills: ' },
  { key: 'workfield', label: 'Current Work Field' },
  { key: 'FULLTEXT', label: '' },
];

export const JobTypeMapping = [
  { value: '1', label: 'Full-time' },
  { value: '2', label: 'Part-time' },
  { value: '3', label: 'Part-time/Full-time' },
];

export const ContractTypeMapping = [
  { value: '1', label: 'Permanent' },
  { value: '2', label: 'Temporary' },
  { value: '3', label: 'Possibly permanent' },
  { value: '4', label: 'Secondment/ Interim' },
  { value: '5', label: 'Franchise' },
  { value: '6', label: 'Side job' },
  { value: '7', label: 'Internship / Graduation position' },
  { value: '8', label: 'Voluntary work' },
  { value: '9', label: 'Freelance' },
  { value: '10', label: 'Apprenticeship' },
];

export const LocationMapping = [
  { value: 'Aberdeen', label: 'Melbourne' },
  { value: 'Grand Prairie', label: 'Melbourne' },
  { value: 'Irvine', label: 'Melbourne' },
  { value: 'Lancashire', label: 'Melbourne' },
  { value: 'London', label: 'Sydney' },
  { value: 'Malvern', label: 'Sydney' },
  { value: 'Manchester', label: 'Sydney' },
  { value: 'Northampton', label: 'Brisbane' },
  { value: 'Reston', label: 'Brisbane' },
  { value: 'Sheffield', label: 'Brisbane' },
  { value: 'Melbourne', label: 'Melbourne' },
  { value: 'Sydney', label: 'Sydney' },
  { value: 'Brisbane', label: 'Brisbane' },
];
