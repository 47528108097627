import React from 'react';
import './custom.scss';
import { Link } from 'react-router-dom';

export default function ImageText (props) {
  return (
    <Link to={props.linkTo}>
      <div className="image-text-wrapper h-100 px-0">
        <img className="w-100 h-100 border-radius" src={props.image} alt='image_text' />
        <div className="content">
          <div className='title'>{props.title}</div>
          <div className='description'>{props.description}</div>
        </div>
      </div>
    </Link>
  );
}
