import React from 'react';
import { Stepper, Step as ReactFormStep } from 'react-form-stepper';
import Color from '../../../Constants/Color';

const styleConfig = {
  activeBgColor: Color.PasianGreen,
  activeTextColor: Color.White,
  completedBgColor: Color.Emerald,
  completedTextColor: Color.White,
  inactiveBgColor: Color.Aluminium,
  inactiveTextColor: Color.White,
  size: '2.5rem'
};

const Step = ({ activeStep = 1, steps = [] }) => {
  const render = () => (
      <Stepper
        activeStep={activeStep}
        styleConfig={styleConfig}
      >
        {steps && steps.length > 0 && steps.map((step, index) => (
          <ReactFormStep key={index} label={step.label}>
            {step.render}
          </ReactFormStep>
        ))}
      </Stepper>
    );
  return render();
};

export default Step;
