import React, { Fragment } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
 Row, Col
} from 'reactstrap';
import Button from '../Button';
import './custom.scss';
import Step from '../Step';

const ModalBase = (props) => {
  const {
    modalIsOpen,
    onToggle,
    data,
    isBodyBreakLine,
    customComponent = 'Custom component',
    className = '',
  } = props;

  return (
    <Modal
      centered
      isOpen={modalIsOpen}
      zIndex={2000}
      className={`ca-modal-base ${className}`}
    >
      {data && (
        <Fragment>
          <ModalHeader>
            <p className="font-weight-bold mb-0">{data.header}</p>
          </ModalHeader>
          <ModalBody>
            <p className={isBodyBreakLine ? 'break-line' : null}>{data.body}</p>
          </ModalBody>
          <ModalFooter>
            {data.buttons.map((item) => (
              <Button
                key={item.value}
                solid={item.solid}
                wrapperClassName="m-2 btn-right"
                onClick={() => onToggle(item.value)}
              >
                {item.label}
              </Button>
            ))}
          </ModalFooter>
        </Fragment>
      )}
      {!data && customComponent}
    </Modal>
  );
};

const ProgressLoading = [
  'Deconstructing resume...',
  'Assessing experience...',
  'Finding key skills...',
  'Matching with jobs...',
];

export const ModalSteps = (props) => {
  const {
    modalIsOpen,
    onToggle,
    onShowJobsList,
    data,
    customComponent = 'Custom component',
    className = '',
    loading = false,
  } = props;

  return (
    <Modal
      centered
      isOpen={modalIsOpen}
      zIndex={2000}
      className={`ca-modal-base ${className}`}
    >
      {data && (
        <Fragment>
          <ModalBody>
            <button className="close close-btn" onClick={onToggle}>
              &times;
            </button>
            {data.steps && data.steps.length > 0 && (
              <Row>
                <Col>
                  <Step activeStep={data.activeStep || 1} steps={data.steps} />
                </Col>
              </Row>
            )}
            {loading && (
              <Row className="modal-loading">
                <Col
                  md={{ size: 8, offset: 2 }}
                  style={{ alignSelf: 'center' }}
                  className="pl-5"
                >
                  <AnimateTag stepActive={data.activeStep} />
                  <div className="bar-loading-resume">
                    <Progress
                      animated
                      color="success"
                      value={data.activeStep * (100 / 3)}
                    />
                  </div>
                </Col>
              </Row>
            )}
            {data.body}
            <Row>
              <Col>
                <section className="text-center">
                  {data.buttons.map((item) => (
                    <Button
                      key={item.value}
                      isDisabled={item.isDisabled}
                      solid={item.solid}
                      wrapperClassName="m-2 btn-right"
                      onClick={() => onShowJobsList(item.value)}
                    >
                      {item.label}
                    </Button>
                  ))}
                </section>
              </Col>
            </Row>
          </ModalBody>
        </Fragment>
      )}
      {!data && customComponent}
    </Modal>
  );
};

export default ModalBase;

const AnimateTag = (props) => {
  const { stepActive } = props;
  let initState = [1, 0, 0, 0];
  switch (stepActive) {
    case 1:
      initState = [2, 1, 0, 0];
      break;
    case 2:
      initState = [0, 2, 1, 0];
      break;
    case 3:
      initState = [0, 0, 2, 1];
      break;
    default:
      break;
  }

  return (
    <Fragment>
      <div className="animate-group">
        {ProgressLoading.map((item, index) => (
                  <p key={index} className={`modal-loading-find ${
                    // eslint-disable-next-line no-nested-ternary
                    initState[index] === 1
                      ? ' item-active'
                      : initState[index] === 2
                      ? 'item-fade-out'
                      : 'item-inactive'
                  }`}
                >
                 {item}
                </p>
        ))}

      </div>
    </Fragment>
  );
};
