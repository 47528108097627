import React from 'react';
import {
  CA_ADMIN_ROLE, CT_CANDIDATE_ROLE
 } from './Constants/common';
import Dashboard from './components/Dashboard/dashboard';
import JobMatching from './components/JobMatching/JobMatching';
import UserSetting from './components/UserSetting/UserSetting';
import Icon from './components/Common/Icon';

export const routeComponents = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    path: '',
    children: [
      {
        path: '',
        allow: [CT_CANDIDATE_ROLE],
        label: 'Dashboard',
        component: Dashboard
      },
      {
        path: 'job-matching',
        allow: [CT_CANDIDATE_ROLE],
        label: 'Dashboard',
        component: JobMatching
      }
    ],
    allow: [CT_CANDIDATE_ROLE],
    exact: true,
    icon: <Icon name="faHome" className="font-size-xl" />
  },
  {
    key: 'user-settings',
    label: 'User Settings',
    path: '/user-settings',
    children: [
      {
        path: '',
        allow: [CT_CANDIDATE_ROLE, CA_ADMIN_ROLE],
        label: 'User Settings',
        component: UserSetting
      }
    ],
    allow: [CT_CANDIDATE_ROLE],
    exact: true,
    icon: <Icon name="faCog" className="font-size-xl" />
  },
];

export const getMenus = (userRole) => {
  const menus = [];
  routeComponents.filter((r) => {
    if (r.allow.includes(userRole)) {
      menus.push({
        key: r.key,
        label: r.label,
        icon: r.icon,
        path: r.path
      });
    }
    return true;
  });
  return menus;
};
