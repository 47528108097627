import React, { useState } from 'react';
import { BeatLoader } from 'react-spinners';

function Image (props) {
  const {
    imageURL = null,
    alt = 'alt',
    className = '',
    imageDefault = IMAGE_DEFAULT,
    imageDefaultBGColor = '#BFE8FF',
    loadingIndicatorSize = '0.5rem',
    loadingIndicatorColor = '#1F2C41'
  } = props;

  const [isLoaded, setIsLoaded] = useState(null);
  const [error, setError] = useState(false);

  return (
    <div className="ca-image-wrapper w-100 h-100 position-relative d-flex align-items-center justify-content-center rounded-circle" style={{ backgroundColor: (isLoaded && imageURL ? '#fafafa' : imageDefaultBGColor)  }}>
      {
        (imageURL && !error) && (
          <img
            onError={() => setError(true)}
            onLoad={() => setIsLoaded(true)}
            className={`w-100 h-100 ${className}`}
            alt={alt}
            src={imageURL}
            style={isLoaded ? {} : { display: 'none' }}
          />
        )
      }
      { (!isLoaded || !imageURL || error) && (
        <div className={`d-flex ${(!isLoaded && imageURL && !error) && 'disabled'}`} style={(!isLoaded && imageURL && !error) ? { filter: 'blur(0.3rem)' } : {}}>
          {imageDefault}
        </div>)
      }
      {(!isLoaded && imageURL && !error) && (<BeatLoader color={loadingIndicatorColor} size={loadingIndicatorSize} css={{ position: 'absolute' }} />)}
    </div>
  );
}

export default Image;

const IMAGE_DEFAULT = <div className="w-100 h-100" style={{ backgroundColor: '#BFE8FF' }}></div>;
