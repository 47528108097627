import React, { Fragment } from 'react';
import './App.css';
import './assets/bootstrap/base.scss';
import MasterPage from './pages/MasterPage';

function App () {
  return (
    <Fragment>
      <MasterPage />
    </Fragment>
  );
}

export default App;
