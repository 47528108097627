export const CA_ADMIN_ROLE = 'CA-Admin';
export const CA_CLIENT_ROLE = 'CA-Client';
export const CA_COACH_ROLE = 'CA-Coach';
export const CA_PARTICIPANT_ROLE = 'CA-Participant';

export const EXT_PORTAL_USER_STATUS_CREATED = 'CREATED';
export const EXT_PORTAL_USER_STATUS_ACTIVE = 'ACTIVE';
export const EXT_PORTAL_USER_STATUS_INACTIVE = 'INACTIVE';
export const EXT_PORTAL_USER_STATUS_ARCHIVED = 'ARCHIVED';

export const AUTH_ACTION_UPDATE_EMAIL = 'UPDATE_EMAIL';
export const AUTH_ACTION_ADD_ROLE = 'ADD_ROLE';
export const AUTH_ACTION_REMOVE_ROLE = 'REMOVE_ROLE';

// Participant action/note
export const PARTICIPANT_ACTION_LOG_OFFLINE_ACTIVITY = 'LOG_OFFLINE_ACTIVITY';
export const PARTICIPANT_ACTION_NO_SHOW = 'NO_SHOW';
export const PARTICIPANT_ACTION_NOTES = 'NOTES';
export const PARTICIPANT_ACTION_LOG_SESSION = 'LOG_SESSION';
export const PARTICIPANT_ACTION_LOG_PREP_TIME = 'LOG_PREP_TIME';
export const PARTICIPANT_ACTION_BOOK_A_SESSION = 'BOOK_A_SESSION';

// Coaching session status
export const COACHING_SESSION_STATUS_PENDING = 'PENDING';
export const COACHING_SESSION_STATUS_BOOKED = 'BOOKED';
export const COACHING_SESSION_STATUS_BOOKING_FAILED = 'BOOKING_FAILED';
export const COACHING_SESSION_STATUS_CANCELLED = 'CANCELLED';
export const COACHING_SESSION_STATUS_CANCELLED_TO_CHARGE = 'CANCELLED_TO_CHARGE';
export const COACHING_SESSION_STATUS_CANCELLING_FAILED = 'CANCELLING_FAILED';
export const COACHING_SESSION_STATUS_COMPLETED = 'COMPLETED';

// Coaching session type
export const COACHING_SESSION_TYPE_STANDARD = 'STANDARD';
export const COACHING_SESSION_TYPE_OFFLINE = 'OFFLINE';
export const COACHING_SESSION_TYPE_NOSHOW = 'NOSHOW';

// color scheme
export const SCHEME_COLOR_COMMOM = 'ca-common-color-scheme';
export const SCHEME_COLOR_PARTICIPANT = 'ca-participant-color-scheme';
export const SCHEME_COLOR_COACH = 'ca-coach-color-scheme';
export const SCHEME_COLOR_CLIENT = 'ca-client-color-scheme';

export const MAX_COACH_SPECIALITIES_TO_SHOW = 4;

// Button actions
export const BUTTON_ACTION_EDIT = 'EDIT';
export const BUTTON_ACTION_DELETE = 'DELETE';

// list Industry for ca participant
export const industryOptions = [
  { label: 'Aerospace & Defence', value: 'Aerospace & Defence' },
  { label: 'Automotive ', value: 'Automotive ' },
  { label: 'Chemical', value: 'Chemical' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Energy & Utilities', value: 'Energy & Utilities' },
  { label: 'Engineering', value: 'Engineering' },
  { label: 'FMCG', value: 'FMCG' },
  { label: 'Financial Services', value: 'Financial Services' },
  { label: 'IT', value: 'IT' },
  { label: 'Industry & Manufacturing', value: 'Industry & Manufacturing' },
  { label: 'Insurance', value: 'Insurance' },
  { label: 'Media & Internet', value: 'Media & Internet' },
  { label: 'Not for Profit', value: 'Not for Profit' },
  { label: 'Pharm. & Healthcare', value: 'Pharm. & Healthcare' },
  { label: 'Professional Services', value: 'Professional Services' },
  { label: 'Property & Real Estate', value: 'Property & Real Estate' },
  { label: 'Public Sector', value: 'Public Sector' },
  { label: 'Retail', value: 'Retail' },
  { label: 'Telecommunications', value: 'Telecommunications' }
];

export const seniorityOptions = [
  { label: 'Individual Contributor', value: 'Individual Contributor' },
  { label: 'Lead Others', value: 'Lead Others' },
  { label: 'Lead Managers', value: 'Lead Managers' },
  { label: 'Lead Divisions', value: 'Lead Divisions' },
  { label: 'Lead the Organisation', value: 'Lead the Organisation' },
];

export const developmentGoals = [
  { label: 'Active learning and learning strategies', value: 'Active learning and learning strategies' },
  { label: 'Analytical thinking and innovation', value: 'Analytical thinking and innovation' },
  { label: 'Building trust', value: 'Building trust' },
  { label: 'Career development', value: 'Career development' },
  { label: 'Change management', value: 'Change management' },
  { label: 'Coaching', value: 'Coaching' },
  { label: 'Collaboration', value: 'Collaboration' },
  { label: 'Communication', value: 'Communication' },
  { label: 'Complex problem solving', value: 'Complex problem solving' },
  { label: 'Creating connections', value: 'Creating connections' },
  { label: 'Creativity', value: 'Creativity' },
  { label: 'Critical Conversations', value: 'Critical Conversations' },
  { label: 'Critical thinking and analysis', value: 'Critical thinking and analysis' },
  { label: 'Culture', value: 'Culture' },
  { label: 'Customer Obsession', value: 'Customer Obsession' },
  { label: 'Decision making', value: 'Decision making' },
  { label: 'Delegating', value: 'Delegating' },
  { label: 'Diversity and Inclusion', value: 'Diversity and Inclusion' },
  { label: 'Emotional Intelligence', value: 'Emotional Intelligence' },
  { label: 'Expectations and Accountability', value: 'Expectations and Accountability' },
  { label: 'Feedback', value: 'Feedback' },
  { label: 'Flexibility', value: 'Flexibility' },
  { label: 'Goal Setting', value: 'Goal Setting' },
  { label: 'Ideation', value: 'Ideation' },
  { label: 'Influencing', value: 'Influencing' },
  { label: 'Initiative', value: 'Initiative' },
  { label: 'Leading others', value: 'Leading others' },
  { label: 'Managing complexity', value: 'Managing complexity' },
  { label: 'Mindfulness', value: 'Mindfulness' },
  { label: 'Motivating and Inspiring others', value: 'Motivating and Inspiring others' },
  { label: 'Networking', value: 'Networking' },
  { label: 'Onboarding', value: 'Onboarding' },
  { label: 'Personal Brand', value: 'Personal Brand' },
  { label: 'Productivity', value: 'Productivity' },
  { label: 'Purpose and vision', value: 'Purpose and vision' },
  { label: 'Reasoning', value: 'Reasoning' },
  { label: 'Remote working', value: 'Remote working' },
  { label: 'Resilience', value: 'Resilience' },
  { label: 'Self-Awareness', value: 'Self-Awareness' },
  { label: 'Strategic analysis and planning', value: 'Strategic analysis and planning' },
  { label: 'Stress tolerance', value: 'Stress tolerance' },
  { label: 'Team building', value: 'Team building' },
  { label: 'Time Management', value: 'Time Management' },
  { label: 'Wellbeing', value: 'Wellbeing' },
  { label: 'Work-life balance', value: 'Work-life balance' }
];
