import React, { Component, createContext } from 'react';
import Amplify, { Hub, Auth } from 'aws-amplify';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import UnsupportedBrowser from '../components/Common/UnsupportedBrowser';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

export const AppContext = createContext();
class AppContextProvider extends Component {
  constructor (props) {
    super(props);
    this.state = {
      auth: {
        isAuthenticated: false,
        isClient: true,
        isAdmin: false,
        isConsultant: false,
        isCoach: false,
        isClientCA: true,
        isAdminCA: false,
        isCoachCA: false,
        role: 'CA-Participant',
        userData: {},
        userAvatar: '',
      },
      data: {},
      isSignOut: false,
      isLoading: false,
      isIE: /* @cc_on!@ */ false || !!document.documentMode,
      isOpera:
        (!!window.opr && !!window.opr.addons)
        || !!window.opera
        || navigator.userAgent.indexOf(' OPR/') >= 0,
      showCoachLocationSetupModal: false,
      isNotificationClicked: false,
    };

    Hub.listen('auth', async ({ payload: { event, data } }) => {
      if (event === 'signIn') {
        this.setState({
          auth: {
            ...this.state.auth,
            role: 'CA-Participant',
            isAuthenticated: true,
            userData: { ...data.attributes },
          },
        });
      } else if (event === 'signOut') {
        sessionStorage.clear();
        this.setState({
          isLoading: true,
          isSignOut: true,
          auth: { isAuthenticated: false },
        });
      }
    });
  }

  checkAuthentication = async () => {
    let user;
    try {
      user = await Auth.currentAuthenticatedUser();
    } catch (error) {
      console.error('checkAuthentication occurred an error', error);
    }
    return user;
  };

  signOut = () => {
    sessionStorage.clear();// To clear all the last activity on sessions storage when user signs out
    Auth.signOut({ global: true }).catch((err) => { Auth.signOut(); });
    this.setState({ isLoading: true, isSignOut: true, auth: { isAuthenticated: false } });
  }

  async componentDidMount () {
    // check user login or not /
    const user = await this.checkAuthentication();

    if (user) {
      this.setState({
        auth: {
          ...this.state.auth,
          isAuthenticated: true,
          userData: { ...user.attributes },
        },
      });
    }
  }

  renderTempView () {
    const {
      isSignOut,
      isIE,
      isOpera,
    } = this.state;
    if (isSignOut) {
      return <AmplifyAuthenticator/>;
    }
    if (isIE || isOpera) {
      return <UnsupportedBrowser />;
    }
    return <AmplifyAuthenticator/>;
  }

  render () {
    const {
      auth: { isAuthenticated },
    } = this.state;
    const events = {
      changePassword: this.changePassword,
      signOut: this.signOut,
      setUserAvatar: this.setUserAvatar,
      getCoachProfileData: this.getCoachProfileData,
      updateUserData: this.updateUserData,
      updateData: this.updateData,
       markNotifiationClick: this.markNotifiationClick,
    };
    return (
      <AppContext.Provider value={{ ...this.state, ...events }}>
        {isAuthenticated
          ? this.props.children
          : this.renderTempView()}
      </AppContext.Provider>
    );
  }
}

export default  AppContextProvider;
