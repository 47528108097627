import React, { useState } from 'react';
import './custom.scss';
import Select from 'react-select';
import { colourStyles } from '../../../Constants/common';

const optionsJobs = [
  { label: 'All Jobs', value: 'All Jobs' },
  { label: 'Internal Jobs', value: 'Internal Jobs' }
];

const optionsLocation = [
  { label: 'Location', value: 'Location' }
];

const optionsDistance = [
  { label: 'Distance', value: 'Distance' },
  { label: '10km', value: '10km' },
  { label: '20km', value: '20km' },
  { label: '30km', value: '30km' }
];

const optionsJobType = [
  { label: 'Type - All', value: 'Type - All' },
  { label: 'Full-Time', value: 'Full-Time' },
  { label: 'Part-Time', value: 'Part-Time' }
];

const optionsContractType = [
  { label: 'Contract - All', value: 'Contract - All' },
  { label: 'Permanent', value: 'Permanent' },
  { label: 'Temporary', value: 'Temporary' },
  { label: 'Possibly permanent', value: 'Possibly permanent' },
  { label: 'Secondment/ Interim', value: 'Secondment/ Interim' },
  { label: 'Franchise', value: 'Franchise' },
  { label: 'Side job', value: 'Side job' },
  { label: 'Internship / Graduation position', value: 'Internship / Graduation position' },
  { label: 'Voluntary work', value: 'Voluntary work' },
  { label: 'Freelance', value: 'Freelance' },
  { label: 'Apprenticeship', value: 'Apprenticeship' }
];

const FilterTable = ({ pagination = false, locationList }) => {
  const [selectedJob, setSelectedJob] = useState(optionsJobs[0]);
  const [selectedLocation, setSelectedLocation] = useState(optionsLocation[0]);
  const [selectedDistance, setSelectedDistance] = useState(optionsDistance[0]);
  const [selectedJobType, setSelectedJobType] = useState(optionsJobType[0]);
  const [selectedContractType, setSelectedContractType] = useState(optionsContractType[0]);
  const handleSelected = (selected, type) => {
    switch (type) {
      case 'Job':
        setSelectedJob(selected);
        break;
      case 'Location':
        setSelectedLocation(selected);
        break;
      case 'Distance':
        setSelectedDistance(selected);
        break;
      case 'Type':
        setSelectedJobType(selected);
        break;
      case 'Contract':
        setSelectedContractType(selected);
        break;
      default:
        break;
    }
  };

  return (
    <div className="wrapper-filter">
      <span>Filters: </span>
      <Select
        options={optionsJobs}
        styles={colourStyles}
        value={selectedJob}
        onChange={(selected) => handleSelected(selected, 'Job')}
        getOptionLabel={(option) => `${option.label}`}
        getOptionValue={(option) => `${option.value}`}
        placeholder="Select..."
        className="wrapper-select"
      />
      <Select
        options={locationList}
        styles={colourStyles}
        value={selectedLocation}
        onChange={(selected) => handleSelected(selected, 'Location')}
        getOptionLabel={(option) => `${option.label}`}
        getOptionValue={(option) => `${option.value}`}
        placeholder="Select..."
        className="wrapper-select"
      />
      <Select
        options={optionsDistance}
        styles={colourStyles}
        value={selectedDistance}
        onChange={(selected) => handleSelected(selected, 'Distance')}
        getOptionLabel={(option) => `${option.label}`}
        getOptionValue={(option) => `${option.value}`}
        placeholder="Select..."
        className="wrapper-select"
      />
      <Select
        options={optionsJobType}
        styles={colourStyles}
        value={selectedJobType}
        onChange={(selected) => handleSelected(selected, 'Type')}
        getOptionLabel={(option) => `${option.label}`}
        getOptionValue={(option) => `${option.value}`}
        placeholder="Select..."
        className="wrapper-select"
      />
      <Select
        options={optionsContractType}
        styles={colourStyles}
        value={selectedContractType}
        onChange={(selected) => handleSelected(selected, 'Contract')}
        getOptionLabel={(option) => `${option.label}`}
        getOptionValue={(option) => `${option.value}`}
        placeholder="Select..."
        className="wrapper-select"
      />
      {pagination && pagination()}
    </div>
  );
};

export default FilterTable;
