import React from 'react';
import { Pagination as PaginationGroup, PaginationItem, PaginationLink } from 'reactstrap';
import Icon from '../Icon';

export default function Pagination ({
  page, rowsPerPage, dataLength, handleChangePage, isMore, className
}) {
  // To handle when lick the prev or next button
  const onClick = (id) => (event) => {
    const result = id === 'prev' ? page - 1 : page + 1;
    const isNext = id !== 'prev';
    handleChangePage(result, isNext);
  };

  // To show pagination info
  const paginationShowCurrentItem = () => {
    const result = 'x-y of z';
    if (dataLength <= rowsPerPage) {
      return result.replace('z', dataLength).replace('y', dataLength).replace('x', dataLength !== 0 ? 1 : 0);
    }
      const x = (page * rowsPerPage) + 1;
      const y = (page * rowsPerPage + rowsPerPage) > dataLength ? dataLength : (page * rowsPerPage + rowsPerPage);
      return result.replace('z', dataLength).replace('y', y).replace('x', x);
  };

  return (
    <PaginationGroup size="md" className="mb-0">
      <div className="d-flex align-items-center">
        <label className="font-weight-bold text-second mb-0">{paginationShowCurrentItem()}</label>
        <PaginationItem className={page === 0 || dataLength === 0 ? 'disabled' : ''}>
          <PaginationLink previous href="#/" id="prev" onClick={onClick('prev')} className="rounded-circle">
            <Icon name="faChevronLeft" />
          </PaginationLink>
        </PaginationItem>
        <PaginationItem className={dataLength === 0 || !isMore ? ' disabled' : ''}>
          <PaginationLink next href="#/" id="next" onClick={onClick('next')} className="rounded-circle">
            <Icon name="faChevronRight" />
          </PaginationLink>
        </PaginationItem>
      </div>
    </PaginationGroup>
  );
}
