import React, { Fragment } from 'react';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import EllipsisText from '../EllipsisText';
import Icon from '../Icon';
import Image from '../Image';

export default function UserProfile (props) {
  const {
    userName,
    role,
    signOut,
    email,
    avatar = ''
  } = props.data;
  const IMAGE_DEFAULT = <Icon regular name="faUser" />;
  let logoutClass = 'dropdown-item';
  switch (role) {
    case 'Participant':
      logoutClass = 'dropdown-item-participant-logout';
      break;
    case 'Coach':
    case 'Client':
      logoutClass = 'dropdown-item-custom-logout';
      break;
    default:
      break;
  }
  return (
    <Fragment>
      <UncontrolledDropdown className="ca-user-profile cursor-pointer">
        <DropdownToggle tag="div" className="d-flex algin-items-center">
          <div className="avatar-icon-wrapper">
            <div className="avatar-icon d-flex justify-content-center align-items-center">
              <Image
                className="rounded-circle"
                loadingIndicatorSize="0.2rem"
                loadingIndicatorColor='#035ade'
                imageURL={avatar}
                imageDefault={IMAGE_DEFAULT}
              />
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center ml-2 ca-user-info">
            {/* <EllipsisText className="font-weight-bold" id="ca-username">Alex Mawby</EllipsisText> */}
            <div>Alex Mawby</div>
          </div>
        </DropdownToggle>
        <DropdownMenu right className={`ca-user-profile-mobile ${role === 'Participant' ? 'px-2 py-2 ca-user-participant-wrapper' : ''}`}>
          <div className="ca-user-info-wrapper">
            <div className="d-flex flex-column justify-content-center ml-2 ca-user-info">
              <div className="font-weight-bold" id="ca-username">Alex Mawby</div>
              <div>{role}</div>
            </div>
          </div>
          {/* {
            role === 'Participant'
              ? <div role="menuitem" onClick={onClickEditProfile}>
                  <span className="dropdown-item cursor-pointer">Edit my profile</span>
              </div>
            : ''
          } */}
          <div role="menuitem" onClick={signOut}>
            <span className={`cursor-pointer ${logoutClass}`}>Logout</span>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Fragment>
  );
}
