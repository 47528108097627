import { orderBy as sortOrderBy } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';

export const getSortedData = (data, fields, fieldName, sort) => {
  let sortedData = null;
  let order;
  if (sort.orderBy === null && sort.order === null) {
    // when data just loaded, the default sort are asc and sortBy Activation Date
    // so this code will check if user sort by Activation Date, data will be sorted desc
    // else will be sorted asc for another
    const defaultSortField = fields.find((f) => f.defaultSort);
    if (defaultSortField && fieldName === defaultSortField.name) {
      order = 'desc';
    } else {
      order = 'asc';
    }
  } else if (fieldName) {
    if (sort.orderBy === fieldName) {
      order = sort.order === 'asc' ? 'desc' : 'asc';
    } else {
      order = 'asc';
    }
  } else {
    order = sort.order;
  }
  const orderBy = fieldName || sort.orderBy;
  const targetField = fields.find((obj) => obj.sort && obj.name === orderBy);
  if (targetField) {
    const idSort = fields.find((obj) => obj.sort && obj.name === orderBy).field;
    switch (targetField.type) {
      case 'String':
        sortedData = sortOrderBy(data, idSort, order);
        break;
      case 'Number':
        sortedData = data.sort((a, b) => {
          const n1 = !a[idSort] || a[idSort] === '' ? 0 : parseInt(a[idSort]);
          const n2 = !b[idSort] || b[idSort] === '' ? 0 : parseInt(b[idSort]);
          return order === 'asc' ? n1 - n2 : n2 - n1;
        });
        break;
      case 'Date': {
        const emptyfieldData = data.filter((i) => !i[idSort] || i[idSort] === '');
        const sortData = data.filter((i) => !!i[idSort]);
        sortedData = sortOrderBy(sortData, (obj) => moment(obj[idSort]).format(), order);
        sortedData = sortedData.concat(emptyfieldData);
        break;
      }
      case 'List':
        sortedData = sortOrderBy(data, (obj) => obj[idSort][0], order);
        break;
      case 'Boolean':
        sortedData = order === 'asc' ? data.sort((a, b) => (b[idSort] - a[idSort])) : data.sort((a, b) => (a[idSort] - b[idSort]));
        break;
      default:
        break;
    }
  }
  return { sortedData, order };
};

// To notify message
export const notify = (message, type = 'success') => toast[type](message, {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 3000,
  // className: type === 'success' ? 'CustomSuccessToast' : ''
});
