import React from 'react';

export default function HamburgerButton (props) {
  const handleHamburgerButtonClick = () => {
    // Show/hide Sidebar
    props.handleHamburgerButtonClick();
  };

  return (
    <button
      className="navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn ca-hamburger"
      onClick={handleHamburgerButtonClick}
    >
      <span className="hamburger-box"><span className="hamburger-inner"></span></span>
    </button>
  );
}
