import React, { useContext } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import HamburgerButton from './hamburgerButton';
import SearchBox from './searchBox';
import UserProfile from './userProfile';
import Icon from '../Icon';
import './custom.scss';

export default function Header (props) {
  const {
    showBackButton = false,
    showSearchBox = true,
    routeName,
    backPath
  } = props;

  const appContext = useContext(AppContext);
  const { userData, role, isAdminCA } = appContext.auth;

  const userProfileProps = {
    name: userData.name,
    email: userData.email,
    role: role.split('CA-')[1],
    signOut: appContext.signOut,
    avatar: appContext.auth.userData.userAvatar || '',
  };

  // const listAllowBackByUrl = ['Participant profile', 'Edit participant'];
  const handleGoBack = () => {
    if (backPath) {
      props.history.push(backPath);
    }
    return true;
  };

  return (
    <div className="app-header app-header--shadow app-header--opacity-bg ca-header">
      <div className="app-header--pane overflow-hidden">
        <HamburgerButton {...props} />
        <div className="d-flex overflow-hidden">
          {showBackButton && <Icon className="cursor-pointer mx-2" name="faChevronLeft" onClick={handleGoBack} />}
          <p className={`font-weight-bold mb-0 ca-page-title ${showSearchBox && 'p-border-right'}`}>{props.pageHeaderTitle || routeName}</p>
        </div>
        {showSearchBox &&  <SearchBox {...props} />}
      </div>
      <div className="app-header--pane">
        <div><UserProfile data={userProfileProps} extraProps={{ isAdminCA }} /></div>
      </div>
    </div>
  );
}
