import React, { useEffect, useRef, useState } from 'react';
import Icon from '../Icon';

export default function SearchBox (props) {
  const searchWrapperRef = useRef(null);
  const firstRender = useRef(true);
  const [searchValue, setSearchValue] = useState('');
  const { getSearchValue, handleScrollToTop } = props;

  useEffect(() => {
    let timer = null;
    if (!firstRender.current) {
      timer = setTimeout(() => {
        handleScrollToTop();
        getSearchValue(searchValue.trim());
      }, 300);
    }
    firstRender.current = false;
    return () => clearTimeout(timer);
  }, [searchValue, getSearchValue, handleScrollToTop]);

  const handleFocusAndBlur = (e) => {
    const { value } = e.target;
    const activeClass = 'is-active';
    if (value === '') {
      // Add/Remove active class in search-wrapper element
      const searchWrapperEl = searchWrapperRef.current;
      if (searchWrapperEl.classList.contains(activeClass)) {
        searchWrapperEl.classList.remove(activeClass);
      } else {
        searchWrapperEl.classList.add(activeClass);
      }
      props.handleSearchBoxClick();
    }
    return true;
  };

  return (
    <div className="header-search-wrapper ca-search-box">
      <div className="search-wrapper" ref={searchWrapperRef}>
        <label className="icon-wrapper text-black" htmlFor="header-search-input">
          <Icon
            name="faSearch"
            className="cursor-pointer"
          />
        </label>
        <input
          className="form-control"
          id="header-search-input"
          name="header-search-input"
          placeholder="Search"
          type="search"
          onFocus={handleFocusAndBlur}
          onBlur={handleFocusAndBlur}
          onChange={(e) => setSearchValue(e.target.value.trim().toLocaleLowerCase())}
        />
      </div>
    </div>
  );
}
