import React, { Fragment } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import HelloMondayLogo from '../../../assets/images/ca/JA_icon.svg';
import CALogoText from '../../../assets/images/ca/ca_logo_text.svg';
import './custom.scss';

export default function Sidebar (props) {
  // Logo
  const logo = HelloMondayLogo;
  const title = 'Internal Mobility';

  // To render menu items
  const renderMenus = () => {
    const { menus } = props;
    const menuItems = menus.map((m) => (
      <Fragment key={m.key}>
        <li onClick={props.others.handleRemoveSearchBoxActiveClass}>
          <NavLink activeClassName="active" className="d-flex align-items-center justify-content-center ca-route-icon" to={m.path} id={m.key}>
            {m.icon}
          </NavLink>
        </li>
        <UncontrolledTooltip boundariesElement=".ca-route-icon" fade={false} placement="right" target={m.key}>{m.label}</UncontrolledTooltip>
      </Fragment>
    ));
    return menuItems;
  };

  return (
    <div className="app-sidebar app-sidebar--collapsed app-sidebar--mini ca-app-sidebar">
      <div className="app-sidebar--header ca-logo">
        <Link
          title={title}
          className="d-flex align-items-center justify-content-center h-100 w-100"
          to='/#'
        >
          <i><img alt="Internal Mobility" src={logo} className="w-100 h-auto" /></i>
        </Link>
      </div>
      <div className="app-sidebar--content ca-sidebar--content d-flex flex-column">
        <PerfectScrollbar>
          <ul className="sidebar-menu-collapsed">
            {renderMenus()}
          </ul>
        </PerfectScrollbar>
        <div>
          <i><img alt="Career Accelerator" src={CALogoText} className="w-100 h-auto" /></i>
        </div>
      </div>
    </div>
  );
}
